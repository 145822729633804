import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalPaper from "components/modals/ModalPaper";
import moment from "moment";
import { StyledButton } from "../StyledActivityButton";
import { ModalProps } from "types/generic";

export const UserModal: React.FC<ModalProps> = ({ row, triggerText }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const title: string = row?.user?.profile
    ? `${row?.user.profile.fullName} - Profile`
    : " ";
  const user = row?.user;
  const calculateAge = (birthDate: string) => {
    if (!birthDate) return null;
    const birth = moment(birthDate);
    const age = moment().diff(birth, "years");

    return `${age}`;
  };

  return (
    <>
      {user ? (
        <ModalPaper
          title={title}
          trigger={<StyledButton>{triggerText}</StyledButton>}
          isOpen={isOpen}
          setOpen={setOpen}
          width={350}
        >
          <Stack direction="column" alignItems="center" spacing={2}>
            <Box
              sx={{
                position: "relative",
                width: 120,
                height: 120,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                borderRadius: "50%",
              }}
            >
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={user?.profile?.avatar}
              />
            </Box>
            {user?.profile?.fullName && (
              <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                display={"flex"}
              >
                {user.profile.fullName}{" "}
                {user.profile.birthDate ? (
                  <Typography
                    style={{ marginLeft: "5px" }}
                    variant="h5"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {" "}
                    {calculateAge(user.profile.birthDate)}
                  </Typography>
                ) : null}
              </Typography>
            )}

            {user?.profile?.jobTitle && (
              <Typography
                variant="body1"
                color="text.secondary"
                textAlign="center"
              >
                {user.profile.jobTitle}
              </Typography>
            )}
            <Stack
              direction="column"
              textAlign="center"
              spacing={1}
              width="100%"
              mt={2}
            >
              {user?.email && (
                <Box>
                  <Typography variant="body1">
                    Email:{` `}
                    {user.email}
                  </Typography>
                </Box>
              )}

              {user?.profile?.phone && (
                <Box>
                  <Typography variant="body1">
                    Phone:{` `}
                    {user.profile.phone}
                  </Typography>
                </Box>
              )}

              {user?.profile?.telegram && (
                <Box>
                  <Typography variant="body1">
                    Telegram:{` `}
                    {user.profile.telegram}
                  </Typography>
                </Box>
              )}

              {user?.profile?.location && (
                <Box>
                  <Typography variant="body1">
                    Location:{` `}
                    {user.profile.location}
                  </Typography>
                </Box>
              )}
            </Stack>
            {user?.profile?.workFrom != null && (
              <Box
                sx={{
                  width: "100%",
                  padding: 1,
                  backgroundColor: "#F6F6F6",
                  borderRadius: 1,
                  textAlign: "center",
                }}
              >
                <Typography variant="body1">
                  Prefers work from {` `}
                  <strong>
                    {user.profile.workFrom === 1 ? "office" : "home"}
                  </strong>
                </Typography>
              </Box>
            )}
          </Stack>
        </ModalPaper>
      ) : (
        <p
          style={{
            cursor: "not-allowed",
            marginLeft: "8px",
            fontWeight: "400",
          }}
        >
          {triggerText}
        </p>
      )}
    </>
  );
};
