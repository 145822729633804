import React, { createContext } from "react";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "../providers/theme";
import AppLayout from "./AppLayout";
import { AuthProvider } from 'providers/auth';

const AppWrapper = ({ children }: {children: React.ReactNode }) => {

  return (
    <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppLayout>{children}</AppLayout>
        </ThemeProvider>
    </AuthProvider>
  );
};

export default AppWrapper;
