import React, { useContext, useEffect, useState } from "react";
import { 
    Box, 
    Button, 
    styled, 
    Typography, 
    Avatar, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText,
    Divider,
    ListItemButton,
    Paper,
    Chip,
    BottomNavigation,
    BottomNavigationAction
} from "@mui/material";
import { WorkOutline, EventNoteOutlined, Person2Outlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { PATHS } from '../../router/routes.js';
import { authContext } from "../../providers/auth";
import { Colors } from '../../constants/colors';

const BottomNavigationMenu = () => {
    const LINK_BUTTONS_MAPPER = [
        { label: 'My Jobs', icon: <WorkOutline />, href: PATHS.JOBS},
        { label: 'Activities', icon: <EventNoteOutlined />, href: PATHS.ACTIVITIES},
        { label: 'Profile', icon: <Person2Outlined />, href: PATHS.PROFILE},
    ]
    const { user } = useContext(authContext); 
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState(LINK_BUTTONS_MAPPER.findIndex(link => pathname.endsWith(link.href)));

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right:0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    navigate(LINK_BUTTONS_MAPPER[newValue].href)
                }}
            >
                {LINK_BUTTONS_MAPPER.map((link, i) => (
                    <BottomNavigationAction label={link.label} icon={link.icon} key={link.label} />
                ))}
            </BottomNavigation>
        </Paper>

    )
};

  
export default BottomNavigationMenu;