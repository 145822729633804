import * as React from "react";
import Popover from "@mui/material/Popover";
import { IconButton } from "@mui/material";

type PopoverProps = {
  trigger: any;
  children: React.ReactNode;
};

export default function BasicPopover({
  trigger: Trigger,
  children,
}: PopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton aria-describedby={popoverId} onClick={handleClick}>
        <Trigger />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <div onClick={handleClose}>{children}</div>
      </Popover>
    </div>
  );
}
