import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import linkedInDefaultIcon from "assets/linked-in-default.png";
import linkedInHoverIcon from "assets/Linked-in-hover.png";
import { useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import MainContainer from "components/containers/MainContainer";
import AdaptiveContainer from "components/containers/AdaptiveContainer";
import PaperContainer from "components/containers/PaperContainer";
import { SOCIAL_AUTH } from "api/mutations";
import { PATHS } from "router/routes";
import { authContext } from "providers/auth";
import Loader from "components/Loader";

const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(6),
}));

const PaperContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useLinkedInSignin(loading, setLoading);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate(PATHS.DASHBOARD_JOBS);
    }
  }, []);
  return (
    <MainContainer>
      <AdaptiveContainer>
        <TitleBox>
          <Typography variant="h1">
            Welcome to <b>JobLink</b> app
          </Typography>
        </TitleBox>
        <PaperContainer title="You have to be logged in to access the dashboard and create a job link">
          <PaperContent>
            {loading ? "Loading..." : <LinkedInButton />}
          </PaperContent>
          <Loader loading={loading} />
        </PaperContainer>
      </AdaptiveContainer>
    </MainContainer>
  );
};

const LinkedInImage = styled("img")(({ theme }) => ({
  padding: theme.spacing(4),
  cursor: "pointer",
  width: "300px",
}));

const LinkedInButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID as string;
  const LINKEDIN_CALLBACK_URL = process.env
    .REACT_APP_LINKEDIN_CALLBACK_URL as string;
  const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    LINKEDIN_CALLBACK_URL,
  )}&scope=email%20profile%20w_member_social%20openid`;

  return (
    <LinkedInImage
      src={isHovered ? linkedInHoverIcon : linkedInDefaultIcon}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      alt="LinkedIn Sign In"
      onClick={() => (window.location.href = linkedinOAuthURL)}
    />
  );
};

const useLinkedInSignin = (
  loading: boolean,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void,
) => {
  const navigate = useNavigate();
  const { setAuth } = useContext(authContext);
  const [code, setCode] = useState<null | string>(null);
  const [mutateSocialAuth, { data: authData, error: authError }] = useMutation(
    SOCIAL_AUTH,
    {
      onCompleted(data) {
        console.log("authDataDebug", data);

        onSignIn(data?.socialAuth?.message);
      },
      onError: (error) => {
        console.error("Apollo mutation error:", error.message);
      },
    },
  );

  useEffect(() => {
    getSearchParam(setCode, "code");
  }, []);

  useEffect(() => {
    code && makeAuthMutation(code);
    code && setLoading(true);
  }, [code]);
  const getSearchParam = (callback: (arg0: string) => void, param: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParam = urlParams.get(param);
    codeParam && callback(codeParam);
  };

  const makeAuthMutation = useCallback(
    (code: string) => {
      mutateSocialAuth({
        variables: {
          accessToken: code,
        },
      });
    },
    [code],
  );

  const onSignIn = (token = null) => {
    if (!token) return;
    token && localStorage.setItem("token", token);
    setLoading(false);
    setAuth(true);
    const job = localStorage.getItem("job");
    if (job) {
      localStorage.removeItem("job");
      setTimeout(() => navigate(`/${job}`), 0);
    } else {
      setTimeout(() => navigate(PATHS.DASHBOARD_JOBS), 0);
    }
  };
};

export default SignIn;
