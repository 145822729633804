import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  Modal,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_JOB_BY_CODE, GET_JOB_CHAT_MESSAGES_BY_USER } from "api/queries";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PaperContainer from "components/containers/PaperContainer";
import CallEndIcon from "@mui/icons-material/CallEnd";
import SendIcon from "@mui/icons-material/Send";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import moment from "moment";
import { CREATE_JOB_LINK_ACTIVITY } from "api/mutations";
import Loader from "components/Loader";
import { PATHS } from "router/routes";
import { purple } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: {};
}
const SendIconStack = styled(Stack)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  height: "100%",
  justifyContent: "center",
}));
const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(5),
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  mt: 2,
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
}));
function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props;

  return (
    <>
      {" "}
      {value === index ? (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          style={{ height: "100%", ...style }}
          {...other}
        >
          {index === 1 ? (
            <Box
              sx={{
                pt: 2,
              }}
            >
              <Typography>{children}</Typography>
            </Box>
          ) : (
            children
          )}
        </div>
      ) : null}
    </>
  );
}
const MessagesStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1.5),
  flex: 1,
  minHeight: 200,
  maxWidth: "100%",
  overflowY: "auto",
  marginBottom: theme.spacing(1),
}));

const AiMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  alignSelf: "flex-start",
}));

const ChatTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  resize: "none",
  padding: theme.spacing(3),
  paddingRight: theme.spacing(6),
  fontFamily: theme.typography.fontFamily,
  borderColor: theme.palette.divider,
}));

const UserMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  alignSelf: "flex-end",
}));
export const JobPage = () => {
  const { urlCode } = useParams();
  const [tabValue, setTabValue] = useState(1);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [callTime, setCallTime] = useState(0);
  const { data, loading } = useQuery(GET_JOB_BY_CODE, {
    variables: {
      jobCode: urlCode,
    },
    fetchPolicy: "no-cache",
  });
  const [messages, setMessages] = useState<any>([]);
  const apolloClient = useApolloClient();
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [inProgress, setProgress] = useState(0);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const handleApplyClick = () => {
    handleCreateActivity("Click on apply button", 6);
    if (job?.applyBtnPurpose === 1) {
      window.open(job?.applyBtnTo, "_blank");
    } else {
      window.location.href = `mailto:${job?.applyBtnTo}`;
    }
  };
  const handleCreateActivity = (text: string, activityType: number) => {
    try {
      const response = apolloClient.mutate({
        mutation: CREATE_JOB_LINK_ACTIVITY,
        variables: {
          jobId: job.id,
          action: text,
          activityType: activityType,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isActive) {
      timer = setInterval(() => {
        setCallTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setCallTime(0);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isActive]);
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleChangeActive = () => {
    setIsInProgress(true);
  };
  const fetchUserMessages = async () => {
    const response = await apolloClient.query({
      query: GET_JOB_CHAT_MESSAGES_BY_USER,
      variables: {
        jobCode: urlCode,
      },
    });
    const responseData = response?.data?.getJobChatMessagesByUser;
    if (responseData.length > 0) {
      const activities = responseData.reduce(
        (
          acc: { [x: string]: any[] },
          item: { createdAt: moment.MomentInput },
        ) => {
          const key: string = moment(item.createdAt).format("DD-MM-YYYY");
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        },
        {},
      );
      const sortedActivities = Object.fromEntries(
        Object.entries(activities).sort(([dateA], [dateB]) =>
          moment(dateA).isBefore(dateB) ? -1 : 1,
        ),
      );
      setMessages(sortedActivities);
    }
  };
  useEffect(() => {
    if (isInProgress) {
      const interval = setInterval(() => {
        if (isInProgress) {
          setProgress((prevState) => prevState + 5);
        }
        if (inProgress === 100) {
          setIsInProgress(false);
          setProgress(0);
          setActive((prevState) => !prevState);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isInProgress, inProgress]);
  useEffect(() => {
    if (!isActive) {
      handleCreateActivity("End call", 5);
    } else {
      handleCreateActivity("Start call", 4);
    }
  }, [isActive]);

  useEffect(() => {
    if (!loading && data?.getJobByCode.__typename === "JobType") {
      if (typeof urlCode === "string") {
        localStorage.setItem("job", urlCode);
      }
      handleCreateActivity("Visited job", 1);
      fetchUserMessages();
    }
  }, [data, loading]);
  if (loading) return <Loader />;
  if (!loading && data?.getJobByCode.__typename === "ErrorType") {
    if (
      data?.getJobByCode?.message ==
      "Authentication required to view this page."
    ) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          bgcolor="white"
          color="black"
        >
          <Typography
            variant="h2"
            sx={{ fontSize: "3rem", color: "grey.600", mb: 2 }}
          >
            Access Denied
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{ mb: 4, color: "grey.400" }}
          >
            You need to be authorized to view this page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(PATHS.SIGN_IN)}
          >
            Login
          </Button>
        </Box>
      );
    }
    navigate("/page-not-found");
    return null;
  }
  const job = data?.getJobByCode;
  console.log(
    !job?.activeUntil,
    moment(job?.activeUntil) < moment(),
    !job?.activeUntil || moment(job?.activeUntil) < moment(),
  );
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mx: "auto",
        height: "100%",
        minWidth: isMobile ? "100%" : "auto",
      }}
    >
      <Modal open={open} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 1,
          }}
        >
          <PaperContainer
            title={`Apply to ${job?.jobTitle} job`}
            closeIcon={true}
            callback={setOpen}
          >
            <Typography>{job?.textAlert}</Typography>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCloseModal();
                  handleApplyClick();
                }}
                sx={{ mt: 2 }}
              >
                Proceed
              </Button>
            </Box>
          </PaperContainer>
        </Box>
      </Modal>
      <Card
        elevation={10}
        sx={{
          mt: 2,
          mb: 2,
          minWidth: {
            xs: "100%",
            sm: "450px",
          },
        }}
      >
        <Stack
          sx={{ width: "100%" }}
          direction="column"
          alignItems={"center"}
          spacing={2}
        >
          <Stack
            sx={{ width: "100%", bgcolor: "#000000", color: "#fff" }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ p: 2 }}
            >
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                {!job?.activeUntil || moment(job?.activeUntil) < moment() ? (
                  <>
                    <VisibilityOutlinedIcon style={{ marginRight: "5px" }} />
                    <Box>ONLY VISIBLE FOR YOU</Box>
                  </>
                ) : null}
              </Box>
              <Button
                sx={{
                  color: "#fff",
                  fontSize: 18,
                  fontWeight: 400,
                  "&:hover": {
                    "::after": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "3px",
                      bottom: -2,
                      left: 0,
                      backgroundColor: "violet.main",
                    },
                  },
                }}
                onClick={() => {
                  if (job?.textAlert) {
                    handleOpenModal();
                  } else {
                    handleApplyClick();
                  }
                }}
              >
                Apply
              </Button>
            </Box>{" "}
            {job?.company ? (
              <Typography variant={"h3"} sx={{ mb: 2 }}>
                {job?.company}
              </Typography>
            ) : null}
            <Typography variant={"h2"}>{job?.jobTitle}</Typography>{" "}
            <Box
              sx={{
                position: "relative",
                width: isMobile ? 90 : 110,
                height: isMobile ? 90 : 110,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                borderRadius: "50%",
                mt: 2,
              }}
            >
              <Avatar
                sx={{
                  height: isMobile ? "80px" : "100px",
                  width: isMobile ? "80px" : "100px",
                }}
                src={job?.createdBy?.profile?.avatar}
              />
            </Box>{" "}
            <Box
              sx={{
                mx: "auto",
                maxWidth: "90%",
                textAlign: "center",
                mt: 2,
              }}
            >
              {job?.createdBy?.profile?.fullName}
            </Box>
            <Box
              sx={{
                mt: 1,
                mb: 3,
                mx: "auto",
                maxWidth: "90%",
                textAlign: "center",
              }}
            >
              {job?.createdBy?.profile?.jobTitle}
            </Box>
          </Stack>
          <Stack sx={{ width: "100%", pb: 1.5 }} alignItems={"center"}>
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleChange}
            >
              <Tab
                value={1}
                sx={{ width: "50%" }}
                label={
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <ChatBubbleOutlineOutlinedIcon
                      sx={{ color: "violet.main" }}
                      fontSize="small"
                    />
                    Сhat
                  </span>
                }
              />
              <Tab
                value={2}
                sx={{ width: "50%" }}
                label={
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <PhoneOutlinedIcon
                      sx={{ color: "violet.main" }}
                      fontSize="small"
                    />
                    Call
                  </span>
                }
              />
            </Tabs>
            <TabPanel
              value={tabValue}
              index={1}
              style={{
                width: "90%",
                minWidth: isMobile ? "90%" : "350px",
                minHeight: 350,
              }}
            >
              <MessagesStack sx={{ minHeight: "100%" }}>
                <MessagesStack
                  sx={{
                    maxHeight: 350,
                    overflowY: "auto",
                    border: "none",
                    p: 0,
                  }}
                >
                  {Object.keys(messages).length > 0
                    ? Object.entries(messages).map(
                        ([date, messageList]: [string, any]) => (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 2,
                              }}
                            >
                              <Divider sx={{ flex: 1 }} />
                              <Typography
                                variant="h6"
                                sx={{ marginX: 2, fontWeight: "bold" }}
                              >
                                {date}
                              </Typography>
                              <Divider sx={{ flex: 1 }} />
                            </Box>
                            {messageList?.map(
                              (
                                item: {
                                  createdAt: moment.MomentInput;
                                  typeFrom: any;
                                  message:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | null
                                    | undefined;
                                },
                                i: React.Key | null | undefined,
                              ) => {
                                switch (item?.typeFrom) {
                                  case 1:
                                    return (
                                      <UserMessageBox key={i}>
                                        <Typography>{item?.message}</Typography>
                                        <Typography
                                          sx={{
                                            textAlign: "right",
                                            fontSize: 12,
                                          }}
                                        >
                                          {moment(item?.createdAt).format("LT")}
                                        </Typography>
                                      </UserMessageBox>
                                    );
                                  case 2:
                                    return (
                                      <AiMessageBox key={i}>
                                        <Typography>{item?.message}</Typography>
                                        <Typography
                                          sx={{
                                            textAlign: "left",
                                            fontSize: 12,
                                          }}
                                        >
                                          {moment(item?.createdAt).format("LT")}
                                        </Typography>
                                      </AiMessageBox>
                                    );
                                }
                              },
                            )}
                          </>
                        ),
                      )
                    : null}
                </MessagesStack>
                <Stack position={"relative"}>
                  <ChatTextarea
                    maxRows={2}
                    // autoFocus={true}
                    // onChange={(e) => setUserMessage(e.target.value)}
                    // onKeyDown={onEnterMessage}
                    // value={userMessage}
                  />
                  <SendIconStack>
                    <IconButton>
                      <SendIcon />
                    </IconButton>
                  </SendIconStack>
                </Stack>
              </MessagesStack>
            </TabPanel>
            <TabPanel
              value={tabValue}
              index={2}
              style={{ height: "100%", minHeight: 363, display: "flex" }}
            >
              <Box
                sx={{
                  pt: 2,
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  height: "100%",
                  flex: 1,
                  margin: "auto",
                }}
              >
                <Typography sx={{ mb: 2 }}>
                  {isActive
                    ? formatTime(callTime)
                    : " Speak to your HR assistant"}
                </Typography>
                {isActive ? "End call" : "Start call"}
                {isInProgress ? (
                  <StyledBox
                    position="relative"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress
                      variant="determinate"
                      size={50}
                      style={{
                        color: purple[500],
                      }}
                      value={inProgress}
                    />
                    <IconButton
                      onClick={() => {
                        setIsInProgress(false);
                        setProgress(0);
                      }}
                      style={{
                        position: "absolute",
                        padding: 0,
                        color: purple[500],
                        fontSize: 28,
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </StyledBox>
                ) : (
                  <CallButton
                    isActive={isActive}
                    onClick={handleChangeActive}
                  />
                )}
              </Box>
            </TabPanel>
          </Stack>
        </Stack>
      </Card>
    </Paper>
  );
};

const CallButtonStack = styled(Stack)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(5),
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
}));

const CallButton: React.FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => {
  return (
    <CallButtonStack onClick={() => onClick()}>
      <Fab
        aria-label="save"
        color={isActive ? "error" : "success"}
        sx={{ width: 50, height: 50, color: "white", fontSize: 22 }}
      >
        {isActive ? <CallEndIcon /> : <PhoneOutlinedIcon />}
      </Fab>
    </CallButtonStack>
  );
};
