import React, { FC, useCallback } from "react";
import { Switch, Typography } from "@mui/material";
import { JobType } from "types/generic";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { ACTIVATE_JOB } from "api/mutations";
import { GET_JOBS } from "api/queries";
import { isActiveDate } from "utils/common";

type JobProps = {
  job: JobType;
};

const ActivateJobButton: FC<JobProps> = ({ job }) => {
  const { id } = job;

  const [mutationActivateJob] = useMutation(ACTIVATE_JOB, {
    refetchQueries: [{ query: GET_JOBS }],
    onCompleted(data) {
      console.log("activateJobDebug", data);
    },
    onError: (error) => {
      console.error("Apollo mutation error:", error.message);
    },
  });
  const onSwitchChange = useCallback(
    (event: React.ChangeEvent, checked: boolean) => {},
    [],
  );

  return (
    <Typography
      style={{ cursor: "pointer" }}
      onClick={() => {
        mutationActivateJob({ variables: { id } });
      }}
    >
      Activate{" "}
      <Switch
        checked={isActiveDate(job?.activeUntil) || false}
        onChange={onSwitchChange}
      />
    </Typography>
  );
};

export default ActivateJobButton;
