import SignIn from "pages/sign-in";
import PageNotFound from "pages/page-not-found";
import DashboardJobs from "pages/dashboard-jobs";
import DashboardProfile from "pages/dashboard-profile";
import DashboardActivities from "pages/dashboard-activities";
import { JobPage } from "pages/job-page";
import Profile from "pages/profile";
import { DashboardJobSettings } from "pages/dashboard-job-settings";
import { DashboardJobGreeting } from "pages/dashboard-job-greeting";
import { DashboardJobKnowledgeBase } from "pages/dashboard-job-knowledge-base";
import { DashboardJobTrainingGuide } from "pages/dashboard-job-training-guide";
import { DashboardJobCreate } from "pages/dashboard-job-create";

export const PATHS = {
  SIGN_IN: "/sign-in",
  DEMO: "/demo/",
  DASHBOARD: "/dashboard",
  JOBS: "jobs",
  ACTIVITIES: "activities",
  PROFILE: "profile",
  DASHBOARD_JOBS: "/dashboard/jobs",
  DASHBOARD_ACTIVITIES: "/dashboard/activities",
  DASHBOARD_PROFILE: "/dashboard/profile",
  DASHBOARD_JOB_TRAINING_GUIDE: "/dashboard/training-guide/:jobId",
  DASHBOARD_JOB_SETTINGS: "/dashboard/job-settings/:jobId",
  DASHBOARD_JOB_GREETING: "/dashboard/job-greeting/:jobId",
  DASHBOARD_JOB_KNOWLEDGE_BASE: "/dashboard/knowledge-base/:jobId",
  DASHBOARD_JOB_CREATE: "/dashboard/job/create",
  JOB_LINK_PROFILE: "/profile/",
  PAGE_NOT_FOUND: "/page-not-found",
  ROOT: "/",
  JOB: "/:urlCode",
  USER_PROFILE: "/profile",
};

export const privatRoutes = [
  {
    path: PATHS.USER_PROFILE,
    component: Profile,
  },
];

export const dashboardRoutes = [
  {
    path: PATHS.JOBS,
    component: DashboardJobs,
  },
  {
    path: PATHS.ACTIVITIES,
    component: DashboardActivities,
  },
  {
    path: PATHS.PROFILE,
    component: DashboardProfile,
  },
  {
    path: PATHS.DASHBOARD_JOB_CREATE,
    component: DashboardJobCreate,
  },
  {
    path: PATHS.DASHBOARD_JOB_TRAINING_GUIDE,
    component: DashboardJobTrainingGuide,
  },
  {
    path: PATHS.DASHBOARD_JOB_KNOWLEDGE_BASE,
    component: DashboardJobKnowledgeBase,
  },
  {
    path: PATHS.DASHBOARD_JOB_GREETING,
    component: DashboardJobGreeting,
  },
  {
    path: PATHS.DASHBOARD_JOB_SETTINGS,
    component: DashboardJobSettings,
  },
];

export const publicRoutes = [
  { path: PATHS.SIGN_IN, component: SignIn },
  {
    path: PATHS.JOB,
    component: JobPage,
  },
  {
    path: PATHS.PAGE_NOT_FOUND,
    component: PageNotFound,
  },
];
