import React, { useContext, useState } from "react";
import PaperContainer from "components/containers/PaperContainer";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { PROFILE_UPDATE } from "api/mutations";
import { Controller, useForm } from "react-hook-form";
import { authContext } from "providers/auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { LocationAutocomplete } from "components/LocationAutocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "router/routes";
import AddIcon from "@mui/icons-material/Add";
import { Person2Outlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
interface StyledListItemButtonProps {
  isactive: string;
}

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "250px",
  background: theme.palette.background.paper,
  borderRadius: 4,
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "fit-content",
  },
}));

const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(
  ({ isactive, theme }) => {
    switch (isactive) {
      case "true":
        return {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          "& svg": {
            color: theme.palette.primary.contrastText,
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
          [theme.breakpoints.down("lg")]: {
            justifyContent: "center",
            borderRadius: 0,
          },
        };
      default:
        return {
          color: theme.palette.primary.main,
          backgroundColor: "unset",
          "& svg": {
            color: theme.palette.primary.main,
          },
          "&:hover": {
            backgroundColor: "secondary.dark",
          },
          [theme.breakpoints.down("lg")]: {
            justifyContent: "center",
            borderRadius: 0,
          },
        };
    }
  },
);

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    display: "flex",
    marginTop: theme.spacing(2),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    padding: `${theme.spacing(1)} 0`,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "unset",
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    marginRight: 0,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  flexShrink: "0",
  borderTop: "0px solid rgba(0, 0, 0, 0.08)",
  borderRight: "0px solid rgba(0, 0, 0, 0.08)",
  borderLeft: "0px solid rgba(0, 0, 0, 0.08)",
  height: "0.0625rem",
  margin: "1rem 0px",
  borderBottom: "none",
  opacity: "0.25",
  backgroundColor: "transparent",
  backgroundImage:
    "linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0)) !important",
}));

const InputLabelProfile = styled(Box)(({ theme }) => ({
  marginBottom: 4,
  color: "black",
  display: "block",
}));
const ProfileItem = ({
  text,
  defaultText,
}: {
  text: string;
  defaultText: string;
}) => {
  const navigate = useNavigate();

  const isValidText = text?.length > 0;

  if (isValidText) {
    return <span>{text}</span>;
  }

  return (
    <Chip
      label={defaultText}
      onClick={() => navigate(PATHS.USER_PROFILE)}
      icon={<AddIcon fontSize="small" />}
    />
  );
};
const Profile = () => {
  const [mutateProfileUpdate] = useMutation(PROFILE_UPDATE);
  const { user } = useContext(authContext);
  const minimized = useMediaQuery("(max-width:1280px)");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const LINK_BUTTONS_MAPPER = [
    {
      label: "Profile",
      icon: <Person2Outlined />,
      href: PATHS.USER_PROFILE,
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: {
      fullName: user?.profile?.fullName,
      jobTitle: user?.profile?.jobTitle,
      contactEmail: user?.profile?.contactEmail,
      phone: user?.profile?.phone,
      location: user?.profile.location
        ? { label: user?.profile.location, id: user?.profile.location }
        : null,
      telegram: user?.profile?.telegram,
      workFrom: user?.profile?.workFrom || 1,
      birthDate: user?.profile.birthDate
        ? moment(user?.profile.birthDate)
        : null,
    },
  });
  const onSubmit = async (values: any) => {
    try {
      await mutateProfileUpdate({
        variables: {
          ...values,
          location: values?.location?.label
            ? values?.location?.label
            : values?.location,
          workFrom: parseInt(values?.workFrom),
          birthDate: values?.birthDate
            ? values?.birthDate.format("YYYY-MM-DD")
            : values?.birthDate,
        },
        refetchQueries: ["me"],
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          mr: minimized ? 0 : 3,
          ml: minimized ? 0 : 3,
          mt: minimized ? 0 : 3,
          height: "100%",
        }}
      >
        {minimized ? null : (
          <Paper sx={{ minHeight: "100%", mr: 3 }}>
            <StyledList>
              <ListItem
                sx={{
                  color: "primary.main",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  // @ts-ignore
                  ...(minimized && {
                    justifyContent: "center",
                  }),
                }}
              >
                <StyledListItemAvatar>
                  <Avatar
                    alt=""
                    sx={{
                      height: minimized ? "60px" : "100px",
                      width: minimized ? "60px" : "100px",
                    }}
                    src={user?.profile?.avatar}
                  />
                </StyledListItemAvatar>
                {!minimized && (
                  <ListItemText
                    primary={
                      <Box style={{ textAlign: "center" }}>
                        {user?.profile?.fullName}
                      </Box>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, textAlign: "center" }}
                      >
                        <ProfileItem
                          text={user?.profile?.jobTitle}
                          defaultText={"Add job title"}
                        />
                      </Typography>
                    }
                  />
                )}
              </ListItem>
              <StyledDivider />
              {LINK_BUTTONS_MAPPER.map((link, i) => (
                <StyledListItem key={i} onClick={() => navigate(link.href)}>
                  <StyledListItemButton
                    isactive={String(pathname.endsWith(link.href))}
                  >
                    <StyledListItemIcon>{link.icon}</StyledListItemIcon>
                    {!minimized && <ListItemText primary={link.label} />}
                  </StyledListItemButton>
                </StyledListItem>
              ))}
            </StyledList>
          </Paper>
        )}

        <PaperContainer
          title={"Profile"}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Box style={{ width: "100%" }}>
                <Stack direction={minimized ? "column" : "row"} spacing={2}>
                  <Stack spacing={2} sx={{ flex: 1 }}>
                    <Box>
                      <InputLabelProfile
                        style={{
                          marginBottom: 4,
                          color: "black",
                          display: "block",
                        }}
                      >
                        Full Name:
                      </InputLabelProfile>
                      <TextField
                        {...register("fullName")}
                        error={
                          Boolean(errors.fullName) && touchedFields.fullName
                        }
                        InputProps={{ style: { height: "40px" } }}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        variant="outlined"
                      />
                    </Box>
                    <Box>
                      <InputLabelProfile
                        style={{ marginBottom: 4, display: "block" }}
                      >
                        Job Title:
                      </InputLabelProfile>
                      <TextField
                        {...register("jobTitle")}
                        error={
                          Boolean(errors.jobTitle) && touchedFields.jobTitle
                        }
                        InputProps={{ style: { height: "40px" } }}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        variant="outlined"
                      />
                    </Box>
                    <Box>
                      <InputLabelProfile
                        style={{ marginBottom: 4, display: "block" }}
                      >
                        Email:
                      </InputLabelProfile>
                      <TextField
                        {...register("contactEmail")}
                        type="email"
                        inputProps={{ maxLength: 255 }}
                        error={
                          Boolean(errors.contactEmail) &&
                          touchedFields.contactEmail
                        }
                        InputProps={{ style: { height: "40px" } }}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                    <Box>
                      <InputLabelProfile
                        style={{ marginBottom: 4, display: "block" }}
                      >
                        Phone:
                      </InputLabelProfile>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput {...field} country={"ua"} />
                        )}
                      />
                    </Box>
                  </Stack>
                  <Stack spacing={2} sx={{ flex: 1 }}>
                    <Box>
                      <InputLabelProfile
                        style={{ marginBottom: 4, display: "block" }}
                      >
                        Birth day:
                      </InputLabelProfile>
                      <Controller
                        name="birthDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            format={"YYYY-MM-DD"}
                            {...field}
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            sx={{
                              width: "100%",
                              height: "40px",
                              "& .MuiInputBase-root": { height: "40px" },
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <InputLabelProfile
                        style={{ marginBottom: 4, display: "block" }}
                      >
                        My current location:
                      </InputLabelProfile>
                      <Controller
                        control={control}
                        name="location"
                        render={({ field: { onChange, value } }) => (
                          <LocationAutocomplete
                            value={value}
                            setValue={setValue}
                          />
                        )}
                      />
                    </Box>{" "}
                    <Box>
                      <InputLabelProfile
                        style={{ marginBottom: 4, display: "block" }}
                      >
                        Telegram:
                      </InputLabelProfile>
                      <TextField
                        {...register("telegram")}
                        inputProps={{ maxLength: 255 }}
                        error={
                          Boolean(errors.telegram) && touchedFields.telegram
                        }
                        InputProps={{ style: { height: "40px" } }}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                    <InputLabelProfile>Prefer work from:</InputLabelProfile>
                    <Controller
                      name="workFrom"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                          value={field.value}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Office"
                            style={{ color: "rgba(0, 0, 0, 0.6)" }}
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Home"
                            style={{ color: "rgba(0, 0, 0, 0.6)" }}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: minimized ? "center" : "end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: minimized ? 4 : 0 }}
                  >
                    Update Profile
                  </Button>
                </Box>
              </Box>
            </form>{" "}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={2000}
              onClose={handleCloseSnackbar}
              message="Profile has been successfully updated"
              action={
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                  sx={{
                    mr: 1,
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: "white",
                    }}
                    fontSize="small"
                  />
                </IconButton>
              }
            />
          </LocalizationProvider>
        </PaperContainer>
      </Box>
    </>
  );
};

export default Profile;
