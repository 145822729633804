import { gql } from "@apollo/client";
import {
  ProfileType,
  JobActivitiesType,
  UserType,
  JobType,
  JobLinkActivityType,
  JobCallType,
  JobChatType,
} from "./schemaTypes";

export const ME = gql`
  query me {
    me {
      dateJoined
      email
      id
      isActive
      isStaff
      ${ProfileType}
    }
  }
`;

export const GET_JOBS = gql`
  query getJobs {
    getJobs {
      id
      jobName
      jobTitle
      voiceType
      greeting
      trainingGuide
      activeUntil
      jobCode
      authRequired
      textAlert
      createdAt
      applyBtnTo
      applyBtnPurpose
      company
      visitors
      chats
      calls
      languages {
        name
        id
      }
    }
  }
`;

export const GET_JOB_ACTIVITIES = gql`
  query getJobActivities {
    getJobActivities {
      id
      ${UserType}
      ${JobType}
      ipAddress
      createdAt
      ${JobLinkActivityType}
      ${JobChatType}
      ${JobCallType}
    }
  }
`;

export const GET_JOB_CHAT_MESSAGES = gql`
  query getJobChatMessages($jobChatId: Int!) {
    getJobChatMessages(jobChatId: $jobChatId) {
      id
      ${JobChatType}
      typeFrom
      message
      createdAt
    }
  }
`;

export const GET_JOB_CALL_MESSAGES = gql`
  query getJobCallMessages($jobCallId: Int!) {
    getJobCallMessages(jobCallId: $jobCallId) {
      id
      ${JobCallType}
      typeFrom
      transcription
      createdAt
    }
  }
`;

export const GET_LOCATION = gql`
  query getCities($cityName: String!) {
    getCities(cityName: $cityName)
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguages {
    getLanguages {
      id
      name
    }
  }
`;
export const CHECK_JOB_LINK = gql`
  query checkLink($jobCode: String!, $jobId: ID!) {
    checkLink(jobCode: $jobCode, jobId: $jobId)
  }
`;
export const GET_JOB_BY_CODE = gql`
  query getJobByCode($jobCode: String!) {
    getJobByCode(jobCode: $jobCode) {
      ... on JobType {
        id
        applyBtnPurpose
        applyBtnTo
        company
        jobTitle
        textAlert
        activeUntil
        createdBy {
          id
          profile {
            fullName
            id
            avatar
            jobTitle
          }
        }
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const GET_JOB_CHAT_MESSAGES_BY_USER = gql`
  query getJobChatMessagesByUser($jobCode: String!) {
    getJobChatMessagesByUser(jobCode: $jobCode) {
      createdAt
      id
      message
      typeFrom
    }
  }
`;

export const GET_JOB_BOOKMARKS = gql`
  query getJobBookmarks {
    getJobBookmarks {
      bookmarkType
      id
      name
      text
    }
  }
`;

export const GET_JOB_BY_ID = gql`
  query getJob($id: ID!) {
    getJob(id: $id) {
      id
      jobName
      jobTitle
      voiceType
      greeting
      trainingGuide
      activeUntil
      jobCode
      authRequired
      textAlert
      createdAt
      applyBtnTo
      applyBtnPurpose
      company
      visitors
      chats
      calls
      languages {
        name
        id
      }
    }
  }
`;
