// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --border-color-default: rgba(0, 0, 0, 0.12);
  --border-color-focus: rgba(0, 0, 0, 0.87);
  --border-radius: 5px;
  --max-height: 40px;
  --transition-duration: 0.3s;
}
.react-tel-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  border:1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  min-height: 40px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.react-tel-input:focus-within::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid var(--border-color-focus);
  border-radius: var(--border-radius);
  pointer-events: none;
  box-sizing: border-box;
  z-index: 1;
}
.react-tel-input .form-control {
  width: 100% !important;
  border: none;
}
.flag-dropdown {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard-profile/style.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,yCAAyC;EACzC,oBAAoB;EACpB,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,mCAAmC;EACnC,oBAAoB;EACpB,sBAAsB;EACtB,UAAU;AACZ;AACA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":[":root {\n  --border-color-default: rgba(0, 0, 0, 0.12);\n  --border-color-focus: rgba(0, 0, 0, 0.87);\n  --border-radius: 5px;\n  --max-height: 40px;\n  --transition-duration: 0.3s;\n}\n.react-tel-input {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  border:1px solid rgba(0, 0, 0, 0.12);\n  border-radius: 5px;\n  min-height: 40px;\n  box-sizing: border-box;\n  transition: border-color 0.3s;\n}\n\n.react-tel-input:focus-within::after {\n  content: '';\n  position: absolute;\n  top: -2px;\n  left: -2px;\n  right: -2px;\n  bottom: -2px;\n  border: 2px solid var(--border-color-focus);\n  border-radius: var(--border-radius);\n  pointer-events: none;\n  box-sizing: border-box;\n  z-index: 1;\n}\n.react-tel-input .form-control {\n  width: 100% !important;\n  border: none;\n}\n.flag-dropdown {\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
