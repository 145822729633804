import React, { useState } from 'react';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseIcon from '@mui/icons-material/PauseCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
    Stack,
    styled,
    IconButton,
} from "@mui/material";
import { JobType } from 'types/generic';
import useUpdateJob from 'components/hooks/useUpdateJob';
import { VOICE_OPTIONS } from 'constants/common';

const RawStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
}));

const SelectVoice = ({ job }: { job: JobType }) => {
    const { voiceType } = job;
    
    const [voice, setVoice] = useState(String(voiceType));
    const [play, setPlay] = useState(false);

    const { onSubmit } = useUpdateJob(job)

    const handleChange = (event: SelectChangeEvent) => {
        setPlay(false)
        setVoice(event.target.value);
        onSubmit({ voiceType: +event.target.value })
    };

    return (
        <RawStack gap={2}>
            <FormControl sx={{ minWidth: 150 }} size="small">
                <Select
                    id="demo-select-small"
                    value={voice}
                    onChange={handleChange}
                >
                    {VOICE_OPTIONS.map((option) => (
                        <MenuItem 
                            key={option.key} 
                            value={option.value}
                        >
                            {option.text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <IconButton 
                aria-label="play/pause" 
                onClick={() => setPlay(!play)}
                sx={{ color: 'violet.main'}}
            >
                {play 
                    ? <PauseIcon fontSize='large' /> 
                    : <PlayIcon fontSize='large' />}
            </IconButton>
        </RawStack>
    )
}

export default SelectVoice;