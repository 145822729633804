import React, { useContext, useEffect } from "react";
import { Box, styled, Stack, Container, AppBar, CssBaseline} from "@mui/material";
import { useLocation } from 'react-router-dom';
import Header from './header';
import { PATHS } from '../router/routes.js';
import { authContext } from "providers/auth";
import Footer from "./Footer";

const RootStack = styled(Stack)(({ theme }) => ({
  height: '100vh',  
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingTop: theme.spacing(8),
}));

  const AppLayout = ({ children }: {children: any }) => {
    const { user } = useContext(authContext); 
    const { pathname } = useLocation();
    const fullLayoutPages = [PATHS.SIGN_IN];

    useEffect(()=>{
      user && console.log('debugUser', user)
    },[user])

    if (matchPathname(pathname, fullLayoutPages)) {
      return (
        <RootStack>
          <Header />
            {children}
          <Footer />
        </RootStack>
      );
    }
  
    return (
        <RootStack>
          <Header />
          <ContentBox>
            {children}
          </ContentBox>
        </RootStack>
    )
  
  };

  const matchPathname = (pathname: string | any[], matchArray: any[]) => {
    return !!matchArray?.find((el) => pathname.includes(el))
  }
  
  export default AppLayout;
  