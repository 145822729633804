import React, { useContext, useEffect } from "react";
import {
  Box,
  styled,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemButton,
  Paper,
  Chip,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import {
  WorkOutline,
  EventNoteOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../router/routes.js";
import { authContext } from "../../providers/auth";

interface StyledListItemButtonProps {
  isactive: string;
}

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "250px",
  //   transition: 'all 0.5s ease-in-out',
  background: theme.palette.background.paper,
  borderRadius: 4,
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "fit-content",
  },
}));

const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(
  ({ isactive, theme }) => {
    switch (isactive) {
      case "true":
        return {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          "& svg": {
            color: theme.palette.primary.contrastText,
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
          [theme.breakpoints.down("lg")]: {
            justifyContent: "center",
            borderRadius: 0,
          },
        };
      default:
        return {
          color: theme.palette.primary.main,
          backgroundColor: "unset",
          "& svg": {
            color: theme.palette.primary.main,
          },
          "&:hover": {
            backgroundColor: "secondary.dark",
          },
          [theme.breakpoints.down("lg")]: {
            justifyContent: "center",
            borderRadius: 0,
          },
        };
    }
  },
);

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    display: "flex",
    marginTop: theme.spacing(2),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    padding: `${theme.spacing(1)} 0`,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "unset",
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    marginRight: 0,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  flexShrink: "0",
  borderTop: "0px solid rgba(0, 0, 0, 0.08)",
  borderRight: "0px solid rgba(0, 0, 0, 0.08)",
  borderLeft: "0px solid rgba(0, 0, 0, 0.08)",
  height: "0.0625rem",
  margin: "1rem 0px",
  borderBottom: "none",
  opacity: "0.25",
  backgroundColor: "transparent",
  backgroundImage:
    "linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0)) !important",
}));

const DashboardSidebar = () => {
  const minimized = useMediaQuery("(max-width:1280px)");
  const { user } = useContext(authContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const LINK_BUTTONS_MAPPER = [
    { label: "My Jobs", icon: <WorkOutline />, href: PATHS.JOBS },
    {
      label: "Activities",
      icon: <EventNoteOutlined />,
      href: PATHS.ACTIVITIES,
    },
    { label: "Profile", icon: <Person2Outlined />, href: PATHS.PROFILE },
  ];

  useEffect(() => {
    console.log("sidebarUserDebug", user);
  }, [user]);

  return (
    <Paper sx={{ height: "100%" }}>
      <StyledList>
        <ListItem
          sx={{
            color: "primary.main",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            ...(minimized && {
              justifyContent: "center",
            }),
          }}
        >
          <StyledListItemAvatar>
            <Avatar
              alt=""
              sx={{
                height: minimized ? "60px" : "100px",
                width: minimized ? "60px" : "100px",
              }}
              src={user?.profile?.avatar}
            />
          </StyledListItemAvatar>
          {!minimized && (
            <ListItemText
              primary={
                <Box style={{ textAlign: "center" }}>
                  {user?.profile?.fullName}
                </Box>
              }
              secondary={
                <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                  <ProfileItem
                    text={user?.profile?.jobTitle}
                    defaultText={"Add job title"}
                  />
                </Typography>
              }
            />
          )}
        </ListItem>
        <StyledDivider />
        {LINK_BUTTONS_MAPPER.map((link, i) => (
          <StyledListItem key={i} onClick={() => navigate(link.href)}>
            <StyledListItemButton
              isactive={String(pathname.endsWith(link.href))}
            >
              <StyledListItemIcon>{link.icon}</StyledListItemIcon>
              {!minimized && <ListItemText primary={link.label} />}
            </StyledListItemButton>
          </StyledListItem>
        ))}
      </StyledList>
    </Paper>
  );
};

const ProfileItem = ({
  text,
  defaultText,
}: {
  text: string;
  defaultText: string;
}) => {
  const navigate = useNavigate();

  const isValidText = text?.length > 0;

  if (isValidText) {
    return <span>{text}</span>;
  }

  return (
    <Chip
      label={defaultText}
      onClick={() => navigate(PATHS.DASHBOARD_PROFILE)}
      icon={<AddIcon fontSize="small" />}
    />
  );
};

export default DashboardSidebar;
