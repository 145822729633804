import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import { CHECK_JOB_LINK, GET_JOB_BY_ID, GET_LANGUAGES } from "api/queries";
import useUpdateJob from "components/hooks/useUpdateJob";
import PaperContainer from "components/containers/PaperContainer";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import useDebounce from "components/hooks/useDebounce";
import Loader from "components/Loader";
import { PATHS } from "../../router/routes";

const FlexStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const Label = styled(InputLabel)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginTop: 5,
}));

const Field = styled(TextField)(({ theme }) => ({
  // '& > *': {
  //     height: 40
  // }
}));
export const DashboardJobSettings = () => {
  const { jobId } = useParams();
  const { data, loading } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id: jobId,
    },
  });
  const { data: dataLanguages, loading: loadingLanguages } =
    useQuery(GET_LANGUAGES);

  const apolloClient = useApolloClient();
  const [isValid, setValid] = useState(true);
  const [isTextAlert, setTextAlert] = useState(false);

  const { onSubmit } = useUpdateJob(data?.getJob);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    // resolver: zodResolver(profileSchema),
    defaultValues: {
      jobTitle: data?.getJob?.jobTitle,
      company: data?.getJob?.company,
      jobCode: data?.getJob?.jobCode,
      authRequired: data?.getJob?.authRequired,
      languageIds:
        data?.getJob?.languages?.map((item: { id: any }) => item?.id) || [],
      applyBtnPurpose: data?.getJob?.applyBtnPurpose
        ? data?.getJob?.applyBtnPurpose
        : 1,
      redirectTo:
        data?.getJob?.applyBtnPurpose === 1 ? data?.getJob?.applyBtnTo : "",
      mailTo:
        data?.getJob?.applyBtnPurpose === 2 ? data?.getJob?.applyBtnTo : "",
      textAlert: data?.getJob?.textAlert,
    },
  });
  const jobCodeCheck = watch("jobCode");
  const applyBtnPurposeCheck: any = watch("applyBtnPurpose");
  useEffect(() => {
    const job = data?.getJob;
    if (job) {
      if ([1, "1"].includes(applyBtnPurposeCheck)) {
        setValue("mailTo", "");
        if (job.applyBtnTo && [1, "1"].includes(job.applyBtnPurpose)) {
          setValue("redirectTo", job.applyBtnTo);
        }
      }
      if ([2, "2"].includes(applyBtnPurposeCheck)) {
        setValue("redirectTo", "");
        if (job.applyBtnTo && [2, "2"].includes(job.applyBtnPurpose)) {
          setValue("redirectTo", job.applyBtnTo);
        }
      }
    }
  }, [setValue, data?.getJob]);
  useEffect(() => {
    if (data) {
      setValue("jobTitle", data.getJob.jobTitle);
      setValue("company", data.getJob.company);
      setValue("jobCode", data.getJob.jobCode);
      setValue("authRequired", data.getJob.authRequired);
      setValue(
        "languageIds",
        data.getJob.languages?.map((item: { id: any }) => item.id) || [],
      );
      setValue("applyBtnPurpose", data.getJob.applyBtnPurpose || 1);
      setValue(
        "redirectTo",
        data.getJob.applyBtnPurpose === 1 ? data.getJob.applyBtnTo : "",
      );
      setValue(
        "mailTo",
        data.getJob.applyBtnPurpose === 2 ? data.getJob.applyBtnTo : "",
      );
      setValue("textAlert", data.getJob.textAlert);
    }
  }, [data, setValue]);

  const debouncedJobCodeCheck = useDebounce(jobCodeCheck, 500);
  const checkJobCode = async () => {
    try {
      setValid(false);
      const response = await apolloClient.query({
        query: CHECK_JOB_LINK,
        variables: {
          jobCode: jobCodeCheck,
          jobId: data?.getJob.id,
        },
        fetchPolicy: "no-cache",
      });
      setValid(response?.data?.checkLink);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debouncedJobCodeCheck) {
      checkJobCode();
    }
  }, [debouncedJobCodeCheck]);
  const onSubmitForm = async (values: any) => {
    const applyBtnTo =
      Number(values.applyBtnPurpose) === 1 ? values.redirectTo : values.mailTo;
    const variables = {
      ...data?.getJob,
      ...values,
      applyBtnTo: applyBtnTo,
      applyBtnPurpose: Number(values.applyBtnPurpose),
    };
    delete variables.mailTo;
    delete variables.redirectTo;
    onSubmit(variables, () => navigate(PATHS.DASHBOARD_JOBS));
  };

  if (loading) return <Loader />;

  return (
    <PaperContainer
      title={"Edit training guide"}
      closeIcon={true}
      callback={() => navigate(PATHS.DASHBOARD_JOBS)}
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack>
          <Label>Job title:</Label>
          <Field
            {...register("jobTitle")}
            error={Boolean(errors.jobTitle) && touchedFields.jobTitle}
            fullWidth
            variant="outlined"
          />
        </Stack>
        <Stack>
          <Label>Company:</Label>
          <Field
            {...register("company")}
            error={Boolean(errors.company) && touchedFields.company}
            fullWidth
            variant="outlined"
          />
        </Stack>
        <Stack>
          <Label>Job URL:</Label>
          <FlexStack>
            <Typography>jobl.app/</Typography>
            <Field
              {...register("jobCode")}
              error={Boolean(errors.jobCode) && touchedFields.jobCode}
              fullWidth
              variant="outlined"
            />
            {isValid ? (
              <FlexStack>
                <CheckIcon sx={{ color: "violet.main" }} />
                <Typography>Valid</Typography>
              </FlexStack>
            ) : (
              <FlexStack>
                <InvalidIcon />
                <Typography>Invalid</Typography>
              </FlexStack>
            )}
          </FlexStack>
        </Stack>
        <Stack>
          <Label>Supported languages</Label>
          <Controller
            name="languageIds"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                multiple
                variant="outlined"
                value={field.value || []}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              >
                {dataLanguages?.getLanguages?.map(
                  (
                    option: { id: string | number; name: string },
                    index: number,
                  ) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            )}
          />
        </Stack>
        <FlexStack sx={{ mt: 1 }}>
          <Stack justifyContent="space-around">
            <Typography>
              Authentification required to open the job link:
            </Typography>
          </Stack>
          <Stack>
            <Controller
              name="authRequired"
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </Stack>
        </FlexStack>
        <Stack>
          <Label>Apply job button:</Label>
          <FlexStack sx={{ width: "100%" }}>
            <Controller
              name="applyBtnPurpose"
              control={control}
              defaultValue={1}
              render={({ field }) => (
                <RadioGroup {...field} sx={{ width: "100%", ml: 1.4 }}>
                  <FlexStack>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Redirect to"
                      style={{ whiteSpace: "nowrap", minWidth: 110 }}
                    />
                    <Controller
                      name="redirectTo"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          disabled={
                            applyBtnPurposeCheck !== 1 &&
                            applyBtnPurposeCheck !== "1"
                          }
                        />
                      )}
                    />
                  </FlexStack>
                  <FlexStack sx={{ mt: 2 }}>
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Mail to"
                      style={{ whiteSpace: "nowrap", minWidth: 110 }}
                    />
                    <Controller
                      name="mailTo"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!error}
                          helperText={error ? error.message : ""}
                          variant="outlined"
                          disabled={
                            applyBtnPurposeCheck !== 2 &&
                            applyBtnPurposeCheck !== "2"
                          }
                        />
                      )}
                    />
                  </FlexStack>
                </RadioGroup>
              )}
            />
          </FlexStack>
        </Stack>

        <Stack>
          <FlexStack
            style={{
              marginLeft: "-10px",
            }}
          >
            <Checkbox
              checked={isTextAlert}
              onChange={(e) => {
                setTextAlert(e.target.checked);
                if (!e.target.checked) {
                  setValue("textAlert", "");
                }
              }}
            />
            <Typography>Text alert by click on the Apply button:</Typography>
          </FlexStack>
          <Field
            {...register("textAlert")}
            multiline
            className="multiline-static"
            error={Boolean(errors.textAlert) && touchedFields.textAlert}
            fullWidth
            disabled={!isTextAlert}
            rows={4}
          />
        </Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "40px",
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate(PATHS.DASHBOARD_JOBS)}
            color={"secondary"}
          >
            Back
          </Button>
          <Button variant="contained" type={"submit"}>
            Update
          </Button>
        </Stack>
      </form>
    </PaperContainer>
  );
};
