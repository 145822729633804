import React from "react";
import { Box, Modal, styled } from "@mui/material";
import PaperContainer from "components/containers/PaperContainer";

const ModalContent = styled(Box)(({ theme }) => ({
  outline: "none",
  backgroundColor: "transparent",
  borderRadius: "4px",
  padding: theme.spacing(5, 4, 5),
  margin: "auto",
  position: "relative",
}));

const ModalPaper = ({
  title,
  trigger,
  children,
  isOpen,
  setOpen,
  width,
  height,
}: {
  title: string;
  trigger: React.ReactElement;
  children: React.ReactNode;
  isOpen: boolean;
  setOpen: (arg1: boolean) => void;
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box onClick={() => setOpen(true)}>{trigger}</Box>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <ModalContent
          sx={{
            width: {
              xs: "98vw",
              sm: "95vw",
              md: "90vw",
              lg: "800px",
              xl: "960px",
            },

            maxWidth: width ?? {
              xs: "98vw",
              sm: "95vw",
              md: "90vw",
              lg: "800px",
              xl: "960px",
            },
            maxHeight: height ?? "auto",
          }}
        >
          <PaperContainer title={title} closeIcon={true} callback={setOpen}>
            {children}
          </PaperContainer>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ModalPaper;
