import React, { useEffect, useState } from "react";
import useDebounce from "./hooks/useDebounce";
import { GET_LOCATION } from "../api/queries";
import { useLazyQuery } from "@apollo/client";
import { SelectAutocomplete } from "./SelectAutocomplete";
import {
  CircularProgress,
  FilledTextFieldProps,
  InputLabel,
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import { JSX } from "react/jsx-runtime";
import Autocomplete from "@mui/material/Autocomplete";

interface OptionType {
  label: string;
  id: string | number;
}

interface LocationAutocompleteProps {
  value: OptionType | null;
  setValue: any;
}

export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  setValue,
  value,
}) => {
  const [cityNameSearch, setCityNameSearch] = useState<string>();
  const debouncedCitySearch = useDebounce(cityNameSearch, 1000);
  const [data, setData] = useState([]);
  const [fetchCities, { loading, data: queryData }] = useLazyQuery(
    GET_LOCATION,
    {
      variables: { cityName: debouncedCitySearch },
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    if (debouncedCitySearch) {
      fetchCities();
    }
  }, [debouncedCitySearch, fetchCities]);

  useEffect(() => {
    if (queryData) {
      setData(
        queryData?.getCities?.map((item: string) => ({
          label: item,
          id: item,
        })),
      );
    }
  }, [queryData]);

  return (
    <Autocomplete
      value={value}
      // @ts-ignore
      onChange={(event: any, newValue: string | null) => {
        setValue("location", newValue);
      }}
      inputValue={cityNameSearch}
      onInputChange={(
        event: any,
        newInputValue: React.SetStateAction<string | undefined>,
      ) => {
        setCityNameSearch(newInputValue);
      }}
      options={data}
      getOptionLabel={(option: { label: any }) => option.label}
      loading={loading}
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
      noOptionsText="No results found"
    />
  );
};
