import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { Colors } from "../../constants/colors";

const PaperRoot = styled(Paper)(({ theme }) => ({
    // padding: theme.spacing(8)
}));

const HeaderStack = styled(Stack)(({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    background: theme.palette.background.default,
    flexDirection: 'row',
    justifyContent: 'space-between'
}));

const ContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const PaperContainer = ({ 
    title, 
    children, 
    style={},
    closeIcon,
    callback,
}: {
    title: string,
    children: React.ReactNode,
    style?: {},
    closeIcon?: boolean,
    callback?: (arg1: boolean) => void,
}) => {

  return (
    <PaperRoot style={style}>
        <Stack>
            <HeaderStack>
                <Typography>{title}</Typography>
                {closeIcon && (
                    <IconButton onClick={() => callback && callback(false)}>
                        <CloseIcon />
                    </IconButton>
                )}
            </HeaderStack>
            <ContentBox>
                {children}
            </ContentBox>
        </Stack>
    </PaperRoot>
  );
};

export default PaperContainer;
