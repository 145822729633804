import { gql } from "@apollo/client";

export const SOCIAL_AUTH = gql`
  mutation MyMutation($accessToken: String!) {
    socialAuth(accessToken: $accessToken) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const PROFILE_UPDATE = gql`
  mutation updateOrCreateAccountProfile(
    $telegram: String
    $phone: String
    $location: String
    $jobTitle: String
    $fullName: String
    $birthDate: Date
    $workFrom: Int
    $contactEmail: String
  ) {
    updateOrCreateAccountProfile(
      profileData: {
        fullName: $fullName
        birthDate: $birthDate
        jobTitle: $jobTitle
        location: $location
        telegram: $telegram
        workFrom: $workFrom
        phone: $phone
        contactEmail: $contactEmail
      }
    ) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB = gql`
  mutation createJob($jobData: JobInputCreateType) {
    createJob(jobData: $jobData) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($jobData: JobInputUpdateType!) {
    updateJob(jobData: $jobData) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: ID!) {
    deleteJob(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB_ACTIVITY = gql`
  mutation createJobActivity($jobId: Int!) {
    createJobActivity(jobId: $jobId) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB_LINK_ACTIVITY = gql`
  mutation createJobLinkActivity(
    $jobId: ID!
    $action: String!
    $activityType: Int!
  ) {
    createJobLinkActivity(
      jobId: $jobId
      action: $action
      activityType: $activityType
    ) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const DUPLICATE_JOB = gql`
  mutation duplicateJob($id: ID!) {
    duplicateJob(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const ACTIVATE_JOB = gql`
  mutation activateJob($id: ID!) {
    activateJob(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB_BOOKMARK = gql`
  mutation createJobBookmark(
    $text: String!
    $name: String!
    $bookmarkType: Int!
  ) {
    createJobBookmark(bookmarkType: $bookmarkType, name: $name, text: $text) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const DELETE_JOB_BOOKMARK = gql`
  mutation deleteJobBookmark($id: ID!) {
    deleteJobBookmark(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
