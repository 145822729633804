import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface OptionType {
  label: string;
  id: string | number;
}
interface SelectAutocompleteProps {
  options: OptionType[];
  onChange: (event: React.SyntheticEvent, value: OptionType | null) => void;
  value: OptionType | null;
  onInputChange?: (event: React.SyntheticEvent, value: string) => void;
  isLoading: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}
export const SelectAutocomplete: React.FC<SelectAutocompleteProps> = ({
  options,
  onChange,
  value,
  onInputChange,
  isLoading,
  placeholder,
  style,
}) => {
  return (
    <Autocomplete
      options={options}
      sx={{ width: "100%", "& .MuiInputBase-root": { height: "40px" } }}
      value={value}
      getOptionLabel={(option) => option?.label}
      onInputChange={onInputChange}
      onChange={onChange}
      loading={isLoading}
      noOptionsText="No results found"
      renderInput={(params) => (
        <TextField placeholder={placeholder || ""} {...params} />
      )}
      style={style}
    />
  );
};
