import React, { useEffect, useContext, createContext, useState } from "react";
import { PATHS } from "router/routes.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import { ME } from "api/queries";
import { LOGOUT_USER } from "../api/mutations";

export const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

export function useAuthProvider() {
  const navigate = useNavigate();
  const [isAuth, setAuth] = useState(!!localStorage.getItem("token"));
  const [menuOpen, setMenuOpen] = useState(false);
  const apolloClient = useApolloClient();
  const { data, error: userError, refetch: refetchUser } = useQuery(ME);

  const logout = () => {
    localStorage.removeItem("token");
    setAuth(null);
    sessionStorage.clear();
    localStorage.clear();
    apolloClient.cache.reset();
    apolloClient.mutate({
      mutation: LOGOUT_USER,
    });
    navigate(PATHS.SIGN_IN);
  };

  useEffect(() => {
    setAuth(!!localStorage.getItem("token"));
  }, []);

  return {
    user: data?.me ?? null,
    userError,
    refetchUser,
    isAuth,
    setAuth,
    logout,
    menuOpen,
    setMenuOpen,
  };
}
