import moment from "moment"

export const sortArrayByDate = (array: any[]) => {
    if (array?.length > 0) return [...array].sort((a: any, b: any) => {
        //@ts-ignore
        return Date.parse(b.createdAt) - Date.parse(a.createdAt)
    })
    return []
}

export const isActiveDate = (date?: string) => {
    if (!date) return false
    return (date && moment() <= moment(date))
}