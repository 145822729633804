import React, { useState } from "react";
import { Box, styled } from "@mui/material";
import ModalPaper from "components/modals/ModalPaper";
import { CreateJobForm } from "components/forms/CreateJobForm";
const ModalBody = styled(Box)(({ theme }) => ({
  // maxHeight: "500px",
  width: "100%",
}));

type CreateJobModalProps = {
  trigger: React.ReactElement;
  onSkip: () => void;
};

const CreateJobModal: React.FC<CreateJobModalProps> = ({ trigger, onSkip }) => {
  const [isOpen, setOpen] = useState(false);
  const onClose = () => {
    setOpen((prevState) => !prevState);
  };
  return (
    <ModalPaper
      title="Create training guide for the job"
      trigger={trigger}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody>
        <CreateJobForm onClose={onClose} />
      </ModalBody>
    </ModalPaper>
  );
};

export default CreateJobModal;
