import React from "react";
import { Box, styled, Stack, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "./Sidebar";
import SidebarDrawer from "./SidebarDrawer";
import BottomNavigationMenu from "./BottomNavigationMenu";

const RootBox = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  padding: theme.spacing(3),
  height: "100%",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: 56,
  },
}));

const DashboardLayout = () => {
  const mobileLayout = useMediaQuery("(max-width:600px)");
  const tabletLayout = useMediaQuery("(max-width:960px)");

  if (mobileLayout) {
    return (
      <RootBox
        direction="column"
        sx={{ padding: mobileLayout ? 0 : "inherit" }}
      >
        <ContentBox>
          <Outlet />
        </ContentBox>
        <BottomNavigationMenu />
      </RootBox>
    );
  }

  return (
    <RootBox>
      <SidebarDrawer />
      {!tabletLayout && <DashboardSidebar />}
      <ContentBox>
        <Outlet />
      </ContentBox>
    </RootBox>
  );
};

export default DashboardLayout;
