import React, { useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Switch,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { JobType } from "types/generic";
import ModalPaper from "components/modals/ModalPaper";
import useUpdateJob from "components/hooks/useUpdateJob";
import { CHECK_JOB_LINK, GET_LANGUAGES } from "api/queries";
import useDebounce from "components/hooks/useDebounce";

type ModalProps = {
  job: JobType;
  trigger: React.ReactElement;
};

const ModalBody = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const ModalFooter = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const FlexStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const Label = styled(InputLabel)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const Field = styled(TextField)(({ theme }) => ({
  // '& > *': {
  //     height: 40
  // }
}));

const SettingsModal = ({ job, trigger }: ModalProps) => {
  const { jobTitle, jobCode, authRequired, textAlert, id, company } = job;
  const [isOpen, setOpen] = useState(false);
  const { onSubmit } = useUpdateJob(job);
  const { data, loading } = useQuery(GET_LANGUAGES);
  const [isTextAlert, setTextAlert] = useState(job?.textAlert ? true : false);
  const apolloClient = useApolloClient();
  const [isValid, setValid] = useState(!!jobCode);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    // resolver: zodResolver(profileSchema),
    defaultValues: {
      jobTitle,
      company,
      jobCode,
      languageIds: job?.languages?.map((item) => item?.id) || [],
      authRequired,
      applyBtnPurpose: job?.applyBtnPurpose ? job.applyBtnPurpose : 1,
      redirectTo: job?.applyBtnPurpose === 1 ? job.applyBtnTo : "",
      mailTo: job?.applyBtnPurpose === 2 ? job.applyBtnTo : "",
      textAlert,
    },
  });

  const onClose = () => {
    setOpen(false);
  };
  const jobCodeCheck = watch("jobCode");
  const applyBtnPurposeCheck: any = watch("applyBtnPurpose");
  const debouncedJobCodeCheck = useDebounce(jobCodeCheck, 500);
  const checkJobCode = async () => {
    try {
      setValid(false);
      const response = await apolloClient.query({
        query: CHECK_JOB_LINK,
        variables: {
          jobCode: jobCodeCheck,
          jobId: id,
        },
        fetchPolicy: "no-cache",
      });
      setValid(response?.data?.checkLink);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debouncedJobCodeCheck) {
      checkJobCode();
    }
  }, [debouncedJobCodeCheck]);
  const onSubmitForm = async (values: any) => {
    const applyBtnTo =
      Number(values.applyBtnPurpose) === 1 ? values.redirectTo : values.mailTo;
    const variables = {
      ...job,
      ...values,
      applyBtnTo: applyBtnTo,
      applyBtnPurpose: Number(values.applyBtnPurpose),
    };
    delete variables.mailTo;
    delete variables.redirectTo;
    onSubmit(variables, onClose);
  };
  const applyBtnPurpose = watch("applyBtnPurpose");

  useEffect(() => {
    if ([1, "1"].includes(applyBtnPurpose)) {
      setValue("mailTo", "");
      if (job.applyBtnTo && [1, "1"].includes(job.applyBtnPurpose)) {
        setValue("redirectTo", job.applyBtnTo);
      }
    }
    if ([2, "2"].includes(applyBtnPurpose)) {
      setValue("redirectTo", "");
      if (job.applyBtnTo && [2, "2"].includes(job.applyBtnPurpose)) {
        setValue("redirectTo", job.applyBtnTo);
      }
    }
  }, [applyBtnPurpose, setValue]);
  if (loading) return <p>Loading</p>;
  return (
    <ModalPaper
      title={`Job settings`}
      trigger={trigger}
      isOpen={isOpen}
      setOpen={setOpen}
      width={750}
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <ModalBody>
          <Stack>
            <Label>Job title:</Label>
            <Field
              {...register("jobTitle")}
              error={Boolean(errors.jobTitle) && touchedFields.jobTitle}
              fullWidth
              variant="outlined"
            />
          </Stack>
          <Stack>
            <Label>Company:</Label>
            <Field
              {...register("company")}
              error={Boolean(errors.company) && touchedFields.company}
              fullWidth
              variant="outlined"
            />
          </Stack>
          <Stack>
            <Label>Job URL:</Label>
            <FlexStack>
              <Typography>jobl.app/</Typography>
              <Field
                {...register("jobCode")}
                error={Boolean(errors.jobCode) && touchedFields.jobCode}
                fullWidth
                variant="outlined"
              />
              {isValid ? (
                <FlexStack>
                  <CheckIcon sx={{ color: "violet.main" }} />
                  <Typography>Valid</Typography>
                </FlexStack>
              ) : (
                <FlexStack>
                  <InvalidIcon />
                  <Typography>Invalid</Typography>
                </FlexStack>
              )}
            </FlexStack>
          </Stack>
          <Stack>
            <Label>Supported languages:</Label>
            <Controller
              name="languageIds"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  multiple
                  variant="outlined"
                  value={field.value || []}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                >
                  {data?.getLanguages?.map(
                    (
                      option: { id: string | number; name: string },
                      index: number,
                    ) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              )}
            />
          </Stack>
          <FlexStack>
            <Stack height="38px" justifyContent="space-around">
              <Typography>
                Authentification required to open the job link:
              </Typography>
            </Stack>
            <Stack>
              <Controller
                name="authRequired"
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Stack>
          </FlexStack>
          <Stack>
            <Label>Apply job button:</Label>
            <FlexStack sx={{ width: "100%" }}>
              <Controller
                name="applyBtnPurpose"
                control={control}
                defaultValue={1}
                render={({ field }) => (
                  <RadioGroup {...field} sx={{ width: "100%", ml: 1.4 }}>
                    <FlexStack>
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Redirect to"
                        style={{ whiteSpace: "nowrap", minWidth: 110 }}
                      />
                      <Controller
                        name="redirectTo"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            disabled={
                              applyBtnPurposeCheck !== 1 &&
                              applyBtnPurposeCheck !== "1"
                            }
                          />
                        )}
                      />
                    </FlexStack>
                    <FlexStack sx={{ mt: 2 }}>
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="Mail to"
                        style={{ whiteSpace: "nowrap", minWidth: 110 }}
                      />
                      <Controller
                        name="mailTo"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : ""}
                            variant="outlined"
                            disabled={
                              applyBtnPurposeCheck !== 2 &&
                              applyBtnPurposeCheck !== "2"
                            }
                          />
                        )}
                      />
                    </FlexStack>
                  </RadioGroup>
                )}
              />
            </FlexStack>
          </Stack>

          <Stack>
            <FlexStack>
              <Checkbox
                checked={isTextAlert}
                onChange={(e) => {
                  setTextAlert(e.target.checked);
                  if (!e.target.checked) {
                    setValue("textAlert", "");
                  }
                }}
              />
              <Typography>Text alert by click on the Apply button:</Typography>
            </FlexStack>
            <Field
              {...register("textAlert")}
              multiline
              className="multiline-static"
              error={Boolean(errors.textAlert) && touchedFields.textAlert}
              fullWidth
              disabled={!isTextAlert}
              rows={4}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant="contained"
              type="submit"
              disabled={
                (jobCodeCheck && !isValid) || (isValid && !jobCodeCheck)
              }
            >
              Update
            </Button>
          </Stack>
        </ModalFooter>
      </form>
    </ModalPaper>
  );
};

export default SettingsModal;
