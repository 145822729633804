import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import { LoaderProps } from "types/generic";

const TopLoaderWrapper = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1300,
});

const Loader: React.FC<LoaderProps> = ({
  message = "Loading...",
  loading = true,
}) => {
  if (!loading) return null;

  return (
    <>
      <TopLoaderWrapper>
        <LinearProgress sx={{ height: 8 }} />
      </TopLoaderWrapper>
    </>
  );
};

export default Loader;
