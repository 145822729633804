import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import Navigation from "components/navigation/Navigation";
import './App.css';
import { authContext } from 'providers/auth';

function App() {
  return (
    <Router>
      <AppWrapper>
        <Navigation />
      </AppWrapper>
    </Router>
  );
}

export default App;
