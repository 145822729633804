import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { UserModal } from "./DashboardActivityModals/UserModal";
import { ActivityModal } from "./DashboardActivityModals/ActivityModal";
import { ChatsModal } from "./DashboardActivityModals/ChatsModal";
import { ChatsSummaryModal } from "./DashboardActivityModals/ChatSummaryModal";
import { CallModal } from "./DashboardActivityModals/CallModal";
import { CallSummaryModal } from "./DashboardActivityModals/CallSummaryModal";
import { styled } from "@mui/system";

interface DataRow {
  ipAddress: string;
  user: {
    profile: {
      id: string;
      fullName: string;
      birthDate: string;
      jobTitle: string;
      location: string;
      phone: string;
      telegram: string;
      avatar: string;
      workFrom: number;
      contactEmail: string | null;
    };
    id: string;
    email: string;
    isActive: boolean;
    isStaff: boolean;
    dateJoined: string;
  };
}

interface JobActivitiesTableProps {
  data: DataRow[];
}
const StyledTabCell = styled(TableCell)(({ theme }) => ({
  padding: "12px 0px 12px  22px",
}));
export const JobActivitiesTable: React.FC<JobActivitiesTableProps> = ({
  data,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedData = data.slice(startIndex, endIndex);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTabCell style={{ width: "20%" }}>User</StyledTabCell>
              <StyledTabCell style={{ width: "15%" }}>Activity</StyledTabCell>
              <StyledTabCell style={{ width: "15%" }}>Chats</StyledTabCell>
              <StyledTabCell style={{ width: "15%" }}>
                Chats summary
              </StyledTabCell>
              <StyledTabCell style={{ width: "15%" }}>Calls</StyledTabCell>
              <StyledTabCell style={{ width: "15%" }}>
                Calls summary
              </StyledTabCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data
                </TableCell>
              </TableRow>
            ) : (
              displayedData.map((row: DataRow, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <UserModal
                      row={row}
                      triggerText={
                        row?.user ? row.user.profile.fullName : row.ipAddress
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <ActivityModal row={row} triggerText={"Click to view"} />
                  </TableCell>
                  <TableCell>
                    <ChatsModal row={row} triggerText={"Click to view"} />
                  </TableCell>
                  <TableCell>
                    <ChatsSummaryModal
                      row={row}
                      triggerText={"Click to view"}
                    />
                  </TableCell>
                  <TableCell>
                    <CallModal row={row} triggerText={"Click to view"} />
                  </TableCell>
                  <TableCell>
                    <CallSummaryModal row={row} triggerText={"Click to view"} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        style={{
          overflow: "hidden",
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
