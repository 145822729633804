import { Button, Stack, styled, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaperContainer from "components/containers/PaperContainer";
import useUpdateJob from "components/hooks/useUpdateJob";
import { useQuery } from "@apollo/client";
import { GET_JOB_BY_ID } from "api/queries";
import Loader from "components/Loader";
import { PATHS } from "../../router/routes";

const Textarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  padding: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
}));

export const DashboardJobGreeting = () => {
  const [greetings, setGreetings] = useState("");
  const { jobId } = useParams();
  const { data, loading } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id: jobId,
    },
  });
  const { onSubmit } = useUpdateJob(data?.getJob);
  const navigate = useNavigate();
  useEffect(() => {
    if (data && !loading) {
      setGreetings(data?.getJob.greeting);
    }
  }, [data, loading]);
  if (loading) return <Loader />;
  return (
    <PaperContainer
      title={"Greeting for Job"}
      closeIcon={true}
      callback={() => navigate(PATHS.DASHBOARD_JOBS)}
      style={{ height: "100%" }}
    >
      <Stack>
        <Textarea
          minRows={22}
          maxRows={22}
          autoFocus={true}
          onChange={(e) => setGreetings(e.target.value)}
          value={greetings}
        />
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "40px",
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(PATHS.DASHBOARD_JOBS)}
          color={"secondary"}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit({ greeting: greetings }, () =>
              navigate(PATHS.DASHBOARD_JOBS),
            );
          }}
        >
          Update
        </Button>
      </Stack>
    </PaperContainer>
  );
};
