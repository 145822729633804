import React from 'react';
import { Typography, Box, styled, Link } from "@mui/material";
import headerLogo from 'assets/header-logo.svg';
import { PATHS } from 'router/routes.js';

const FooterBox = styled(Box)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    
}));

const WrapperBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2)
}));

const FooterTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.paper,
    textAlign: 'center',
    padding: theme.spacing(1),
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
}));

function Footer() {
  return (
    <FooterBox>
      <WrapperBox>
        <FooterTypography>
            <Link href='mailto:contact@jobl.app' target='_blank'>contact@jobl.app</Link>
        </FooterTypography>
        <FooterTypography>
            JobLink {new Date().getFullYear()}
        </FooterTypography>
      </WrapperBox>
    </FooterBox>
  );
}

export default Footer;
