import React, { useContext, useState } from "react";
import PaperContainer from "components/containers/PaperContainer";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  styled,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { PROFILE_UPDATE } from "api/mutations";
import { Controller, useForm } from "react-hook-form";
import { authContext } from "providers/auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { z } from "zod";
import moment from "moment";
import { LocationAutocomplete } from "components/LocationAutocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { zodResolver } from "@hookform/resolvers/zod";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "pages/dashboard-profile/style.css";

const InputLabelProfile = styled(Box)(({ theme }) => ({
  marginBottom: 4,
  color: "black",
  display: "block",
}));
const DashboardProfile = () => {
  const [mutateProfileUpdate] = useMutation(PROFILE_UPDATE);
  const { user } = useContext(authContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: {
      fullName: user?.profile?.fullName,
      jobTitle: user?.profile?.jobTitle,
      contactEmail: user?.profile?.contactEmail,
      phone: user?.profile?.phone,
      location: user?.profile.location
        ? { label: user?.profile.location, id: user?.profile.location }
        : null,
      telegram: user?.profile?.telegram,
      workFrom: user?.profile?.workFrom || 1,
      birthDate: user?.profile.birthDate
        ? moment(user?.profile.birthDate)
        : null,
    },
  });
  const onSubmit = async (values: any) => {
    try {
      await mutateProfileUpdate({
        variables: {
          ...values,
          location: values?.location?.label
            ? values?.location?.label
            : values?.location,
          workFrom: parseInt(values?.workFrom),
          birthDate: values?.birthDate
            ? values?.birthDate.format("YYYY-MM-DD")
            : values?.birthDate,
        },
        refetchQueries: ["me"],
      }).then((r) => {
        const resData = r?.data;
        if (
          resData?.updateOrCreateAccountProfile?.__typename === "SuccessType"
        ) {
          setOpenSnackbar(true);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <Box>
      <PaperContainer title={"Profile"}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <Box style={{ width: "100%" }}>
              <Stack direction={isMobile ? "column" : "row"} spacing={2}>
                <Stack spacing={2} sx={{ flex: 1 }}>
                  <Box>
                    <InputLabelProfile
                      style={{
                        marginBottom: 4,
                        color: "black",
                        display: "block",
                      }}
                    >
                      Full Name:
                    </InputLabelProfile>
                    <TextField
                      {...register("fullName")}
                      error={Boolean(errors.fullName) && touchedFields.fullName}
                      InputProps={{ style: { height: "40px" } }}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <InputLabelProfile
                      style={{ marginBottom: 4, display: "block" }}
                    >
                      Job Title:
                    </InputLabelProfile>
                    <TextField
                      {...register("jobTitle")}
                      error={Boolean(errors.jobTitle) && touchedFields.jobTitle}
                      InputProps={{ style: { height: "40px" } }}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <InputLabelProfile
                      style={{ marginBottom: 4, display: "block" }}
                    >
                      Email:
                    </InputLabelProfile>
                    <TextField
                      {...register("contactEmail")}
                      type="email"
                      inputProps={{ maxLength: 255 }}
                      error={
                        Boolean(errors.contactEmail) &&
                        touchedFields.contactEmail
                      }
                      InputProps={{ style: { height: "40px" } }}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <InputLabelProfile
                      style={{ marginBottom: 4, display: "block" }}
                    >
                      Phone:
                    </InputLabelProfile>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput {...field} country={"ua"} />
                      )}
                    />
                  </Box>
                </Stack>
                <Stack spacing={2} sx={{ flex: 1 }}>
                  <Box>
                    <InputLabelProfile
                      style={{ marginBottom: 4, display: "block" }}
                    >
                      Birth day:
                    </InputLabelProfile>
                    <Controller
                      name="birthDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          {...field}
                          value={field.value}
                          onChange={(date) => field.onChange(date)}
                          sx={{
                            width: "100%",
                            height: "40px",
                            "& .MuiInputBase-root": { height: "40px" },
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <InputLabelProfile
                      style={{ marginBottom: 4, display: "block" }}
                    >
                      My current location:
                    </InputLabelProfile>
                    <Controller
                      control={control}
                      name="location"
                      render={({ field: { onChange, value } }) => (
                        <LocationAutocomplete
                          value={value}
                          setValue={setValue}
                        />
                      )}
                    />
                  </Box>{" "}
                  <Box>
                    <InputLabelProfile
                      style={{ marginBottom: 4, display: "block" }}
                    >
                      Telegram:
                    </InputLabelProfile>
                    <TextField
                      {...register("telegram")}
                      inputProps={{ maxLength: 255 }}
                      error={Boolean(errors.telegram) && touchedFields.telegram}
                      InputProps={{ style: { height: "40px" } }}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <InputLabelProfile>Prefer work from:</InputLabelProfile>
                  <Controller
                    name="workFrom"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "5px",
                        }}
                        value={field.value}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Office"
                          style={{ color: "rgba(0, 0, 0, 0.6)" }}
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label="Home"
                          style={{ color: "rgba(0, 0, 0, 0.6)" }}
                        />
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "end",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: isMobile ? 4 : 0 }}
                >
                  Update Profile
                </Button>
              </Box>
            </Box>
          </form>
        </LocalizationProvider>
      </PaperContainer>{" "}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Profile has been successfully updated"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={handleCloseSnackbar}
            sx={{
              mr: 1,
            }}
          >
            <CloseIcon
              sx={{
                color: "white",
              }}
              fontSize="small"
            />
          </IconButton>
        }
      />
    </Box>
  );
};

export default DashboardProfile;
