import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Stack,
  Paper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import { AddCircleOutlineIcon } from '@mui/icons-material';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { GET_JOBS } from "api/queries";
import { CREATE_JOB, UPDATE_JOB } from "api/mutations";
import Job from "components/Dashboard/Jobs/Job";
import MobileJob from "components/Dashboard/Jobs/MobileJob";
import { JobType } from "types/generic";
import { sortArrayByDate } from "utils/common";
import CreateJobModal from "components/modals/CreateJob";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../router/routes";

const RootJobsStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
  },
}));

const HeaderStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const JobsStack = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
  },
  "&:last-child": {
    paddingBottom: 0,
  },
}));

const DashboardJobs = () => {
  const { data: jobs } = useQuery(GET_JOBS);
  const { mutateCreateJob } = useJobsMutations();
  const tabletLayout = useMediaQuery("(max-width:960px)");
  const navigate = useNavigate();
  return (
    <RootJobsStack
      sx={{
        backgroundColor: tabletLayout ? "#FFFFFF" : "inherit",
      }}
    >
      <HeaderStack>
        {tabletLayout ? (
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            color="primary"
            onClick={() => navigate(PATHS.DASHBOARD_JOB_CREATE)}
            sx={{ fontSize: tabletLayout ? "13px" : "inherit" }}
          >
            CREATE NEW JOB
          </Button>
        ) : (
          <CreateJobModal
            trigger={
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
                color="primary"
                sx={{ fontSize: tabletLayout ? "13px" : "inherit" }}
              >
                CREATE NEW JOB
              </Button>
            }
            onSkip={mutateCreateJob}
          />
        )}
        <Typography
          sx={{
            whiteSpace: "nowrap",
            fontSize: tabletLayout ? "15px" : "inherit",
          }}
        >
          {jobs?.getJobs?.length ?? 0}/20 Jobs available
        </Typography>
      </HeaderStack>
      <JobsStack gap={3}>
        {sortArrayByDate(jobs?.getJobs)?.map((job: JobType, i: React.Key) =>
          tabletLayout ? (
            <MobileJob key={job.id} job={job} />
          ) : (
            <Job key={job.id} job={job} />
          ),
        )}
      </JobsStack>
    </RootJobsStack>
  );
};

const useJobsMutations = () => {
  const [mutateCreateJob, { data: jobCreated, error: createJobError }] =
    useMutation(CREATE_JOB, {
      variables: {
        jobData: null,
      },
      refetchQueries: [GET_JOBS],
      onCompleted(data) {
        console.log("createJobDebug", data);
      },
      onError: (error) => {
        console.error("Apollo mutation error:", error.message);
      },
    });

  return {
    mutateCreateJob,
  };
};

export default DashboardJobs;
