import React, { useContext } from "react";
import { Box, Drawer } from "@mui/material";
import { authContext } from "providers/auth";
import DashboardSidebar from "./Sidebar";

const SidebarDrawer = () => {
  const { menuOpen, setMenuOpen } = useContext(authContext);

  return (
    <Drawer
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      // sx={{ '& > *': { height: '100%' }}}
    >
      <DashboardSidebar />
    </Drawer>
  );
};

export default SidebarDrawer;
