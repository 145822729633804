import React, { useState } from "react";
import {
  Box,
  Stack,
  styled,
  Typography,
  IconButton,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { JobType } from "types/generic";
import { VOICE_MAPPER } from "constants/common";
import { calc } from "@chakra-ui/react";

const ChatStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
}));

const ChatTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  padding: theme.spacing(3),
  paddingRight: theme.spacing(6),
  fontFamily: theme.typography.fontFamily,
  borderColor: theme.palette.divider,
}));

const MessagesStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 3,
  flex: 1,
  height: 495,
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    minHeight: '45vh',
    maxHeight: '45vh',
  }
}));

const AiMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "85%",
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const UserMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "85%",
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  alignSelf: "flex-end",
}));

const SendIconStack = styled(Stack)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  height: "100%",
  justifyContent: "center",
}));

const Chat = ({ job }: { job: JobType }) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [userMessage, setUserMessage] = useState("");
  const [messages, setMessages] = useState([
    { text: "This call recorded for quality. Thanks for calling!", type: "ai" },
  ]);

  const onEnterMessage = (
    e: React.KeyboardEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLElement>,
  ) => {
    if (!(userMessage?.length > 0)) return;

    const send = () => {
      setMessages((prev) => [...prev, { text: userMessage, type: "user" }]);
      setUserMessage("");
    };
    switch (e.type) {
      case "click":
        {
          send();
        }
        break;
      case "keydown": {
        const keyboardEvent = e as React.KeyboardEvent<HTMLTextAreaElement>;
        if (keyboardEvent.key === "Enter" && e.shiftKey == false) {
          keyboardEvent.preventDefault();
          send();
        }
      }
    }
  };

  return (
    <ChatStack>
      {!isMobile && <Typography variant="h5">{`Chat with ${VOICE_MAPPER?.[job.voiceType]}`}</Typography>}
      <MessagesStack>
        {messages.map((message, i) => {
          switch (message.type) {
            case "ai":
              return (
                <AiMessageBox key={i}>
                  <Typography>{message.text}</Typography>
                </AiMessageBox>
              );
            case "user":
              return (
                <UserMessageBox key={i}>
                  <Typography>{message.text}</Typography>
                </UserMessageBox>
              );
          }
        })}
      </MessagesStack>
      <Stack position={"relative"}>
        <ChatTextarea
          maxRows={2}
          // autoFocus={true}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={onEnterMessage}
          value={userMessage}
        />
        <SendIconStack>
          <IconButton onClick={onEnterMessage}>
            <SendIcon />
          </IconButton>
        </SendIconStack>
      </Stack>
    </ChatStack>
  );
};

export default Chat;
