import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Paper,
  styled,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import { JobType } from "types/generic";
import SettingsPopover from "./SettingsPopover";
import SelectVoice from "./SelectVoice";
import GreetingModal from "components/modals/Greetings";
import SettingsModal from "components/modals/Settings";
import TrainingGuideModal from "components/modals/TrainingGuide";
import JobNameModal from "components/modals/JobName";
import KnowledgeBaseModal from "components/modals/KnowledgeBase";
import ActivateJobButton from "./ActivateJobButton";
import { isActiveDate } from "utils/common";
import CloseIcon from "@mui/icons-material/Close";

type JobProps = {
  job: JobType;
  key: React.Key;
};

const JobPaper = styled(Paper)(({ theme }) => ({
  height: 250,
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("lg")]: {
    height: 220,
  },
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "5fr 1fr 2fr",
  gridTemplateColumns: "repeat(6, 1fr)",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    "& p": {
      fontSize: "14px",
    },
  },
}));

const RawStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const EditBox = styled(Box)(({ theme }) => ({
  alignSelf: "end",
}));

const Job: FC<JobProps> = ({ job }) => {
  const { id, jobName, activeUntil } = job;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <JobPaper>
      <GridContainer>
        <Box>
          <RawStack gap={1}>
            <Typography>{jobName}</Typography>
            <IconButton sx={{ height: 40 }}>
              <JobNameModal job={job} />
            </IconButton>
          </RawStack>
        </Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography>Visitors: {job.visitors}</Typography>
          )}
        </Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography>Chats: {job.chats}</Typography>
          )}
        </Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography>Calls: {job.calls}</Typography>
          )}
        </Box>
        <Box></Box>
        <Box></Box>
        <Box>
          <LabelTypography>VOICE</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>GREETING</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>TRAINING GUIDE</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>SETTINGS</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>KNOWLEDGE BASE</LabelTypography>
        </Box>
        <Box>
          <RawStack style={{ marginTop: "-8px" }}>
            <ActivateJobButton job={job} />
          </RawStack>
        </Box>
        <EditBox sx={{ minWidth: 220 }}>
          <SelectVoice job={job} />
        </EditBox>
        <EditBox>
          <GreetingModal
            job={job}
            trigger={
              <Button variant="contained" sx={{ width: "fit-content" }}>
                Edit
              </Button>
            }
          />
        </EditBox>
        <EditBox>
          <TrainingGuideModal
            job={job}
            trigger={
              <Button variant="contained" sx={{ width: "fit-content" }}>
                Edit
              </Button>
            }
          />
        </EditBox>
        <EditBox>
          <SettingsModal
            job={job}
            trigger={
              <Button variant="contained" sx={{ width: "fit-content" }}>
                Edit
              </Button>
            }
          />
        </EditBox>
        <EditBox sx={{ minWidth: 240 }}>
          <RawStack gap={2}>
            <KnowledgeBaseModal
              job={job}
              trigger={
                <Button variant="contained" sx={{ width: "fit-content" }}>
                  Edit
                </Button>
              }
            />
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              message={
                job?.jobCode
                  ? "Job link copied to clipboard!"
                  : "Error! Fill in all fields in the Job settings form to receive link"
              }
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              action={
                <IconButton
                  size="small"
                  onClick={handleCloseSnackbar}
                  sx={{
                    mr: 1,
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: "black",
                    }}
                    fontSize="small"
                  />
                </IconButton>
              }
              sx={{
                "& .MuiSnackbarContent-root": {
                  backgroundColor: "#fff",
                  color: job?.jobCode ? "green" : "red",
                  borderRadius: 2,
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  fontWeight: "500",
                  padding: "10px 20px",
                },
              }}
            />
          </RawStack>
        </EditBox>
        <EditBox style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (job?.jobCode) {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_URL}${job?.jobCode}`,
                );
              }
              setOpenSnackbar(true);
            }}
          >
            COPY JOB LINK
          </Button>

          <SettingsPopover id={id} />
        </EditBox>
      </GridContainer>{" "}
    </JobPaper>
  );
};

export default Job;
