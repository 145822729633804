import React, { useState, useEffect } from "react";
import { DayPicker, Matcher } from "react-day-picker";
import "react-day-picker/style.css";
import moment, { Moment } from "moment";
import "./style.css";
interface SelectedDays {
  from: Moment | undefined;
  to: Moment | undefined;
  enteredTo: Moment | undefined;
}

const RangeDatePicker: React.FC<{
  selectedDays: SelectedDays;
  setSelectedDays: React.Dispatch<React.SetStateAction<SelectedDays>>;
}> = ({ selectedDays, setSelectedDays }) => {
  const [modifiers, setModifiers] = useState<{
    start?: Moment;
    end?: Moment;
    highlight?: Moment[];
  }>({
    start: selectedDays.from,
    end: selectedDays.enteredTo,
  });

  const isSelectingFirstDay = (
    from: Moment | undefined,
    to: Moment | undefined,
    day: Date,
  ): boolean => {
    const isBeforeFirstDay =
      from && moment(day).isBefore(moment(from).startOf("day"));
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || (isRangeSelected as unknown as boolean);
  };

  const handleDayClick = (day: Date) => {
    const momentDay = moment(day);
    if (!selectedDays.from || selectedDays.from.isAfter(momentDay)) {
      setSelectedDays({
        from: momentDay,
        to: undefined,
        enteredTo: undefined,
      });
    } else {
      setSelectedDays({
        from: selectedDays.from,
        to: momentDay,
        enteredTo: momentDay,
      });
    }
  };

  const handleDayMouseEnter = (day: Date) => {
    const { from, to } = selectedDays;
    if (!isSelectingFirstDay(from, to, day)) {
      setSelectedDays((prevState) => ({
        ...prevState,
        enteredTo: moment(day),
      }));
    }
  };

  useEffect(() => {
    setModifiers({
      start: selectedDays.from,
      end: selectedDays.enteredTo,
      highlight:
        selectedDays.from && selectedDays.enteredTo
          ? [selectedDays.from, selectedDays.enteredTo]
          : [],
    });
  }, [selectedDays]);

  return (
    <div>
      <DayPicker
        id="Calendar"
        mode="range"
        selected={
          selectedDays.from && selectedDays.to
            ? { from: selectedDays.from.toDate(), to: selectedDays.to.toDate() }
            : undefined
        }
        numberOfMonths={2}
        modifiers={modifiers as Record<string, Matcher | Matcher[]>}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
      />
    </div>
  );
};

export default RangeDatePicker;
