import React, { useState } from "react";
import { Box, Button, Stack, styled, TextareaAutosize } from "@mui/material";
import { JobType } from "types/generic";
import ModalPaper from "components/modals/ModalPaper";
import useUpdateJob from "components/hooks/useUpdateJob";

type GreetingsProps = {
  job: JobType;
  trigger: React.ReactElement;
};

const ModalBody = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const ModalFooter = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const Textarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  padding: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
}));

const GreetingsModal = ({ job, trigger }: GreetingsProps) => {
  const { greeting, jobName } = job;

  const [isOpen, setOpen] = useState(false);
  const [greetings, setGreetings] = useState(greeting);

  const { onSubmit } = useUpdateJob(job);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ModalPaper
      title={`Greeting for ${jobName}`}
      trigger={trigger}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody>
        <Stack>
          <Textarea
            minRows={22}
            maxRows={22}
            autoFocus={true}
            onChange={(e) => setGreetings(e.target.value)}
            value={greetings}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          <Button
            variant="contained"
            onClick={() => {
              onSubmit({ greeting: greetings }, onClose);
              onClose();
            }}
          >
            Update
          </Button>
        </Stack>
      </ModalFooter>
    </ModalPaper>
  );
};

export default GreetingsModal;
