import { createTheme, alpha, getContrastRatio } from "@mui/material/styles";
import { green, grey, purple } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    violet: Palette["primary"];
  }
  interface PaletteOptions {
    violet?: PaletteOptions["primary"];
  }
}

const primaryMain = "#000";
const secondaryMain = grey[100];
const violetMain = purple[500];

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryMain,
      dark: alpha(primaryMain, 0.8),
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryMain,
      dark: alpha(secondaryMain, 0.5),
      contrastText: primaryMain,
    },
    background: {
      default: secondaryMain,
      paper: "#fff",
    },
    violet: {
      main: violetMain,
      light: alpha(violetMain, 0.5),
      dark: alpha(violetMain, 0.9),
      contrastText:
        getContrastRatio(violetMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
    text: {
      primary: primaryMain,
      secondary: grey[700],
    },
  },
  typography: {
    h1: {
      fontSize: 36,
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 17,
    },
    h6: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: "fit-content",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: "-8px -8px",
          color: "#000",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 40,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "#EAEAEA",
            // color: "#fff",
          },
          borderRadius: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 24,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "unset",
          marginRight: "16px",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        // root: {
        //   color: 'red'
        // }
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: violetMain,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: `${violetMain}`,
          },
          "& .Mui-selected": {
            color: `${violetMain} !important`,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: `${violetMain} !important`,
            backgroundColor: purple[50],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-track": {
            backgroundColor: alpha(violetMain, 0.5),
          },
        },
        switchBase: {
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: purple[700],
            },
            "& .MuiSwitch-thumb": {
              color: violetMain,
            },
          },
        },
        thumb: {
          color: alpha(violetMain, 0.7),
        },
        track: {
          backgroundColor: alpha(violetMain, 0.5),
          "&.Mui-checked": {
            backgroundColor: purple[900],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: `${violetMain}`,
          "&.Mui-checked": {
            color: `${violetMain}`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${violetMain}`,
          "&.Mui-checked": {
            color: `${violetMain}`,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(violetMain, 0.2),
        },
        bar: {
          backgroundColor: alpha(violetMain, 0.3),
        },
      },
    },
  },
});
