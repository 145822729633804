import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button)(() => ({
  border: "none",
  backgroundColor: "inherit",
  fontWeight: "400",
  color: "#000",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));
