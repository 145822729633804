import React, { useMemo } from 'react';
import { Box } from "@mui/material";
import { PATHS } from '../../router/routes';

const AdaptiveContainer = ({ children }: { children: React.ReactNode }) => {
    const calcWeight = { base: '327px', sm: '450px', md: '600px', lg: '620px', xl: '640px' };

    return (
        <Box width={calcWeight}>
            {children}
        </Box>
    );
};

export default AdaptiveContainer;
