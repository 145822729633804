import React from 'react';
import { styled } from "@mui/material/styles";
import { Box, Switch, FormLabel, FormHelperText, SxProps, Theme } from "@mui/material";
import { PATHS } from 'router/routes';


const ContainerBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const MainContainer = ({
    children, 
    style,
}: {
    children: React.ReactNode,
    style?: SxProps<Theme>,
} ) => {
    return (
        <ContainerBox
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { base: '10px', sm: '0px' },
                height: { base: 'auto' },
                flex: 1,
                width: '100%',
                ...style
            }}
        >
            {children}
        </ContainerBox>
    );
};

export default MainContainer;
