import { authContext } from 'providers/auth.js';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { PATHS, privatRoutes, publicRoutes, dashboardRoutes } from 'router/routes.js';
import ProtectedRoute from './ProtectedRoute.jsx';
import DashboardLayout from '../Dashboard/Layout';

const Navigation = () => {
    const { isAuth } = useContext(authContext);

    return (
        <Routes>
            {publicRoutes.map((route) => {
                return <Route key={route.path} element={<route.component />} path={route.path} />;
            })}
            <Route element={<ProtectedRoute />}>
                <Route path={PATHS.DASHBOARD} element={<DashboardLayout />}>
                    {dashboardRoutes.map((route) => (
                        <Route key={route.path} element={<route.component />} path={route.path} />
                    ))}
                </Route>
                {privatRoutes.map((route) => {
                    return <Route key={route.path} element={<route.component />} path={route.path} />;
                })}
            </Route>
            <Route path='/' element={<Navigate to={isAuth === false ? PATHS.SIGN_IN : PATHS.DASHBOARD_JOBS} replace />} />
            <Route path='/*' element={<Navigate to={PATHS.PAGE_NOT_FOUND} replace />} />
        </Routes>
    );
};

export default Navigation;
