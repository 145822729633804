import React, { useEffect, useState } from "react";
import ModalPaper from "components/modals/ModalPaper";
import { Box, Divider, Paper, Typography } from "@mui/material";
import moment from "moment";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import { StyledButton } from "../StyledActivityButton";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { JobLinkActivity, ModalProps } from "types/generic";

interface JobLinkActivities {
  [date: string]: JobLinkActivity[];
}

export const ActivityModal: React.FC<ModalProps> = ({ row, triggerText }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [jobLinkActivities, setJobLinkActivities] = useState<JobLinkActivities>(
    {},
  );

  const title: string = row?.user?.profile
    ? `${row.user.profile.fullName} - Activity`
    : `${row?.ipAddress} - Activity`;
  useEffect(() => {
    if (row?.jobLinkActivities && row.jobLinkActivities.length > 0) {
      const activities = row.jobLinkActivities.reduce(
        (acc: JobLinkActivities, item) => {
          const key: string = moment(item.createdAt).format("DD-MM-YYYY");
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        },
        {} as JobLinkActivities,
      );
      const sortedActivities = Object.fromEntries(
        Object.entries(activities).sort(([dateA], [dateB]) =>
          moment(dateA).isBefore(dateB) ? -1 : 1,
        ),
      );

      setJobLinkActivities(sortedActivities);
    }
  }, [row?.jobLinkActivities]);
  const getIcon = (iconType: number | null | string) => {
    switch (iconType) {
      case 1:
        return (
          <TourOutlinedIcon
            sx={{ marginRight: 1, color: "green", fontSize: 32 }}
          />
        );
      case 2:
        return (
          <ChatOutlinedIcon
            sx={{ marginRight: 1, color: "green", fontSize: 32 }}
          />
        );
      case 3:
        return (
          <ChatOutlinedIcon
            sx={{ marginRight: 1, color: "red", fontSize: 32 }}
          />
        );
      case 4:
        return (
          <CallOutlinedIcon
            sx={{ marginRight: 1, color: "green", fontSize: 32 }}
          />
        );
      case 5:
        return (
          <CallOutlinedIcon
            sx={{ marginRight: 1, color: "red", fontSize: 32 }}
          />
        );
      case 6:
        return (
          <CheckCircleOutlinedIcon
            sx={{ marginRight: 1, color: "green", fontSize: 32 }}
          />
        );
      default:
        return (
          <RadioButtonCheckedIcon
            sx={{ marginRight: 1, color: "green", fontSize: 32 }}
          />
        );
    }
  };
  return (
    <>
      <ModalPaper
        title={title}
        trigger={<StyledButton>{triggerText}</StyledButton>}
        isOpen={isOpen}
        setOpen={setOpen}
      >
        <>
          <Box>
            {Object.keys(jobLinkActivities).length > 0 ? (
              Object.entries(jobLinkActivities).map(([date, activities]) => (
                <Box key={date} sx={{ marginBottom: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Divider sx={{ flex: 1 }} />
                    <Typography
                      variant="h6"
                      sx={{ marginX: 2, fontWeight: "bold" }}
                    >
                      {date}
                    </Typography>
                    <Divider sx={{ flex: 1 }} />
                  </Box>
                  {activities.map((activity) => (
                    <Paper
                      key={activity.id}
                      elevation={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 2,
                        marginY: 1,
                        borderRadius: 2,
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {getIcon(activity.activityType)}
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {moment(activity.createdAt).format("lll")}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          {activity.text}
                        </Typography>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              ))
            ) : (
              <Box
                sx={{ display: "flex", justifyContent: "center", padding: 2 }}
              >
                <Typography variant="body1" color="text.secondary">
                  No activities yet.
                </Typography>
              </Box>
            )}
          </Box>
        </>
      </ModalPaper>
    </>
  );
};
