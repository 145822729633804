import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_JOB_BY_ID } from "../../api/queries";
import useUpdateJob from "../../components/hooks/useUpdateJob";
import {
  Box,
  Button,
  CardContent,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import KnowledgeBaseTextModal from "../../components/modals/KnowledgeBaseText";
import React, { useRef, useState } from "react";
import { Colors } from "../../constants/colors";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/HighlightOffOutlined";
import { sortArrayByDate } from "../../utils/common";
import { PATHS } from "../../router/routes";
type KnowledgeBaseInstance = {
  id: string;
  type: string;
  text: string;
  createdAt: string;
  active: boolean;
  file?: File;
};

type KnowledgeInstanceRowProps = {
  instanceData: KnowledgeBaseInstance;
};

export const DashboardJobKnowledgeBase = () => {
  const { jobId } = useParams();
  const { data, loading } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id: jobId,
    },
  });
  const uploadRef = useRef<HTMLInputElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [knowledgeData, setKnowledgeData] = useState([
    {
      id: "0",
      type: "File",
      text: "Speaker 0: Harry Potter and the Philosophers length test",
      createdAt: new Date().toLocaleString(),
      active: true,
    },
    {
      id: "1",
      type: "Text",
      text: "Text2",
      createdAt: new Date().toLocaleString(),
      active: false,
    },
    {
      id: "2",
      type: "Text",
      text: "Test",
      createdAt: new Date().toLocaleString(),
      active: true,
    },
  ]);

  const { onSubmit } = useUpdateJob(data?.getJob);
  const navigate = useNavigate();
  const generateNextId = (arrayWithIds: any[]) => {
    return String(+arrayWithIds[arrayWithIds?.length - 1].id + 1);
  };

  const createText = (text: string) => {
    setKnowledgeData((prev) => [
      ...prev,
      {
        id: generateNextId(knowledgeData),
        type: "Text",
        text,
        createdAt: new Date().toLocaleString(),
        active: true,
      },
    ]);
  };

  const createFile = (input: any) => {
    if (!input.target?.files) return;

    let file = input.target.files[0];

    let reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function () {
      setKnowledgeData((prev) => [
        ...prev,
        {
          id: generateNextId(knowledgeData),
          type: "File",
          text: reader.result as string,
          createdAt: new Date().toLocaleString(),
          active: true,
          file,
        },
      ]);
    };
  };

  return (
    <Box
      sx={{
        m: 2,
      }}
    >
      <Stack direction={"column"} gap={2} mb={3}>
        <Typography variant="h3" flex="1" textAlign={"left"}>
          Agent Knowledge Base
        </Typography>
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          gap={1}
        >
          <KnowledgeBaseTextModal submit={createText} />
          <Button
            style={{
              fontSize: "12px",
            }}
            variant="contained"
            fullWidth
          >
            Fetch URL
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => uploadRef?.current?.click()}
            style={{
              fontSize: "12px",
            }}
          >
            Upload File
          </Button>
          <input
            ref={uploadRef}
            type="file"
            accept=".txt"
            style={{ display: "none" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              createFile(e);
              e.currentTarget.value = "";
            }}
          />
        </Box>
        {sortArrayByDate(knowledgeData).map((instanceData, i) => (
          <KnowledgeInstanceСard instanceData={instanceData} />
        ))}{" "}
        <Button
          variant="contained"
          onClick={() => navigate(PATHS.DASHBOARD_JOBS)}
          color={"secondary"}
          fullWidth
        >
          Back
        </Button>
      </Stack>{" "}
    </Box>
  );
};

const KnowledgeInstanceСard: React.FC<KnowledgeInstanceRowProps> = ({
  instanceData,
}) => {
  const { type, text, createdAt, active } = instanceData;
  const [isActive, setActive] = useState(active);

  return (
    <Paper
      sx={{
        mb: 2,
        borderRadius: 1,
        width: "100%",
        minWidth: "fit-content",
      }}
    >
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          SOURCE TYPE
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600, mb: 1 }}>
          {type}
        </Typography>

        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          TEXT
        </Typography>
        <Typography variant="body1" sx={{ mb: 1.5 }}>
          {text}
        </Typography>

        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          DATE CREATED
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {createdAt}
        </Typography>

        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          ACTIVE
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
        >
          <Switch
            checked={isActive}
            onChange={() => setActive(!isActive)}
            size="small"
          />
          {isActive ? <CheckIcon /> : <InvalidIcon />}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <Button variant="contained" fullWidth size="small">
            Delete
          </Button>
        </Stack>
      </CardContent>
    </Paper>
  );
};
