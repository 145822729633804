import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Button,
  Toolbar,
  styled,
  IconButton,
  Box,
  Typography,
  Avatar,
  Menu,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import headerLogo from "assets/header-logo.svg";
import { PATHS } from "router/routes.js";
import { authContext } from "providers/auth";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { LOGOUT_USER } from "../api/mutations";
import { useApolloClient } from "@apollo/client";
const HeaderLogo = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.only("sm")]: {
    flex: 1,
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  marginLeft: "auto",
  border: "1px solid rgba(255, 255, 255, 0.6)",
  padding: "6px 16px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.not("sm")]: {
    display: "none",
  },
}));

function Header() {
  const { isAuth, setAuth, menuOpen, setMenuOpen, user } =
    useContext(authContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  const apolloClient = useApolloClient();
  const handleMenuOpen = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  const excludedPaths = ["/page-not-found", "/sign-in"];
  const isUrlCodePath =
    !excludedPaths.includes(location.pathname) &&
    /^\/[^/]+$/.test(location.pathname);

  const logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
    navigate(PATHS.SIGN_IN);
    localStorage.removeItem("token");
    setAuth(null);
    sessionStorage.clear();
    localStorage.clear();
    apolloClient.cache.reset();
    apolloClient.mutate({
      mutation: LOGOUT_USER,
    });
    navigate(PATHS.SIGN_IN);
    handleMenuClose();
  };
  return (
    <AppBar>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StyledIconButton
          aria-label="open drawer"
          onClick={() => setMenuOpen(!menuOpen)}
          edge="start"
          sx={[]}
        >
          <MenuIcon />
        </StyledIconButton>
        <HeaderLogo
          src={headerLogo}
          onClick={() => (window.location.href = PATHS.ROOT)}
          style={{ height: isMobile ? 24 : 32 }}
          alt=""
        />
        {isUrlCodePath && (
          <>
            {!isAuth && (
              <LogoutButton
                onClick={() => {
                  navigate(PATHS.SIGN_IN);
                }}
              >
                Sign in
              </LogoutButton>
            )}
            {isAuth && user && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    transition: "background-color 0.3s ease, border 0.3s ease",
                    "@media (max-width: 768px)": {
                      padding: "2px 6px",
                    },
                  }}
                  onClick={handleMenuOpen}
                >
                  <Avatar
                    src={user?.profile?.avatar}
                    sx={{
                      height: "24px",
                      width: "24px",
                      marginRight: "5px",
                      "@media (max-width: 768px)": {
                        height: "20px",
                        width: "20px",
                        marginRight: "3px",
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    color="#fff"
                    sx={{
                      fontSize: "0.875rem",
                      "@media (max-width: 768px)": {
                        fontSize: "0.85rem",
                      },
                    }}
                  >
                    {user.profile.fullName}
                  </Typography>
                  <ArrowDropDownIcon sx={{ color: "#fff", ml: 0.5 }} />
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: 2,
                      minWidth: 140,
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(PATHS.PROFILE);
                      handleMenuClose();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      "&:hover": { backgroundColor: "#f5f5f5" },
                      "@media (max-width: 768px)": {
                        fontSize: "0.75",
                      },
                    }}
                  >
                    <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                    Profile
                  </MenuItem>
                  <Divider sx={{ backgroundColor: "#555" }} />
                  <MenuItem
                    onClick={logout}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      "&:hover": { backgroundColor: "#f5f5f5" },
                      "@media (max-width: 768px)": {
                        fontSize: "0.75",
                      },
                    }}
                  >
                    <ExitToAppOutlinedIcon sx={{ mr: 1 }} />
                    Log Out
                  </MenuItem>
                </Menu>
              </div>
            )}
          </>
        )}

        {isAuth && !isUrlCodePath && (
          <LogoutButton onClick={logout}>Log Out</LogoutButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
