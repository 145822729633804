import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import { PATHS } from "router/routes.js";
const PageNotFound = () => {
  const navigate = useNavigate();

  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const handleGoHome = () => {
    navigate(isAuth ? PATHS.SIGN_IN : PATHS.DASHBOARD);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="90vh"
      color="black"
    >
      <Typography
        variant="h1"
        sx={{ fontSize: "6rem", color: "grey.600", mb: 2 }}
      >
        404
      </Typography>
      <Typography variant="h5" align="center" sx={{ mb: 4, color: "grey.400" }}>
        Page not found.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go to Home
      </Button>
    </Box>
  );
};

export default PageNotFound;
