import React, { useContext, useEffect } from 'react';
import { authContext } from 'providers/auth';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { PATHS } from 'router/routes';

const ProtectedRoute = () => {
    const { refetchUser, isAuth, user, userError, logout } = useContext(authContext);
    const { pathname } = useLocation();

    useEffect(()=>{
        refetchUser()
    },[isAuth])

    useEffect(()=>{
        interceptUnauthorisedUser(userError)
    },[userError]);

    const interceptUnauthorisedUser = (userError) => {
        if (!userError || pathname.includes(PATHS.SIGN_IN)) return;
        logout()
    }

    if (!isAuth) {
        return <Navigate to={PATHS.SIGN_IN} replace />;
    }

    if (!user) {
        return <></>
    }

    return <Outlet />;
};

export default ProtectedRoute;
