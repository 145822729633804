import { Box, Button, Card, Theme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

import moment from "moment";
import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import DateRangePicker from "./DateRangePicker";
import { Moment } from "moment/moment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { purple } from "@mui/material/colors";
interface DateFilterProps {
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
}
interface SelectedDays {
  from: Moment | undefined;
  to: Moment | undefined;
  enteredTo: Moment | undefined;
}

export const DateFilter: React.FC<DateFilterProps> = ({
  setStartDate,
  setEndDate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDays, setSelectedDays] = useState<SelectedDays>({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  });
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [selectedButton, setSelectedButton] = useState<number>(0);
  const handleSetAll = () => {
    setEndDate(null);
    setStartDate(null);
    setSelectedButton(0);
  };
  const DateButton = styled(Button)<{ selected: boolean }>(
    ({ selected, theme }) => ({
      border: "none",
      backgroundColor: "inherit",
      color: "#000",
      fontSize: "14px",
      ...(selected && {
        backgroundColor: purple["50"],
        color: theme.palette.violet.main,
      }),
      "&:hover": {
        backgroundColor: purple["50"],
      },
      [theme.breakpoints.up("md")]: {
        margin: "0 2px",
        padding: "2px 4px",
      },
      [theme.breakpoints.up("lg")]: {
        margin: "0 4px",
        padding: "8px 12px",
      },
    }),
  );
  const handleSetToday = () => {
    const today = moment().startOf("day");
    setStartDate(today.toDate());
    setEndDate(today.toDate());
    setSelectedButton(1);
  };

  const handleSetYesterday = () => {
    const yesterday = moment().subtract(1, "days").startOf("day");
    setStartDate(yesterday.toDate());
    setEndDate(yesterday.toDate());
    setSelectedButton(2);
  };

  const handleSetThisWeek = () => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");
    setStartDate(startOfWeek.toDate());
    setEndDate(endOfWeek.toDate());
    setSelectedButton(3);
  };

  const handleSetLastWeek = () => {
    const startOfLastWeek = moment().subtract(1, "week").startOf("week");
    const endOfLastWeek = moment().subtract(1, "week").endOf("week");
    setStartDate(startOfLastWeek.toDate());
    setEndDate(endOfLastWeek.toDate());
    setSelectedButton(4);
  };

  const handleSetThisMonth = () => {
    const startOfMonth = moment().startOf("month");
    const endOfMonth = moment().endOf("month");
    setStartDate(startOfMonth.toDate());
    setEndDate(endOfMonth.toDate());
    setSelectedButton(5);
  };

  const handleSetLastMonth = () => {
    const startOfLastMonth = moment().subtract(1, "month").startOf("month");
    const endOfLastMonth = moment().subtract(1, "month").endOf("month");
    setStartDate(startOfLastMonth.toDate());
    setEndDate(endOfLastMonth.toDate());
    setSelectedButton(6);
  };
  const handleClick = (event: any) => {
    setSelectedButton(7);
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (selectedButton !== 7 && selectedDays?.from && selectedDays?.to) {
      setSelectedDays({
        from: undefined,
        to: undefined,
        enteredTo: undefined,
      });
    }
  }, [selectedButton]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dateButtons = [
    { label: "All", handle: handleSetAll },
    { label: "Today", handle: handleSetToday },
    { label: "Yesterday", handle: handleSetYesterday },
    { label: "This week", handle: handleSetThisWeek },
    { label: "Last week", handle: handleSetLastWeek },
    { label: "This month", handle: handleSetThisMonth },
    { label: "Last month", handle: handleSetLastMonth },
    { label: "Select period", handle: handleClick },
  ];

  const handleSelectChange = (event: { target: { value: string } }) => {
    const index = dateButtons.findIndex(
      (item) => item.label === event.target.value,
    );
    dateButtons[index].handle(null);
    setSelectedButton(index);
  };
  useEffect(() => {
    if (selectedDays.from && selectedDays.to) {
      setStartDate(selectedDays.from?.toDate());
      setEndDate(selectedDays.to?.toDate());
      handleClose();
    }
  }, [selectedDays]);
  return (
    <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
      {isMobile ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {" "}
          <Select
            value={
              selectedButton !== null ? dateButtons[selectedButton].label : ""
            }
            sx={{
              width: 160,
              color: purple[500],
            }}
            onChange={handleSelectChange}
            variant={"outlined"}
          >
            {dateButtons.map((item, index) => (
              <MenuItem
                style={{
                  display: index === 7 ? "none" : "inherit",
                }}
                key={index}
                value={item.label}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "4px",
              border: "1px solid rgb(200,202,204)",
            }}
          >
            <DateRangeIcon
              onClick={handleClick}
              fontSize="large"
              style={{
                color: selectedButton === 7 ? purple[500] : "inherit",
              }}
            />
          </Button>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <>
            {dateButtons.map((item, index) => (
              <DateButton
                key={item.label}
                onClick={item.handle}
                selected={selectedButton === index}
              >
                {item.label}
              </DateButton>
            ))}{" "}
          </>
        </Box>
      )}{" "}
      <Popover
        id="Calendar"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card style={{ padding: "15px" }}>
          <DateRangePicker
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
          />
        </Card>
      </Popover>
    </Box>
  );
};
