import React, { useState } from "react";
import { Box, Button, Stack, styled, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { JobType } from "types/generic";
import ModalPaper from "components/modals/ModalPaper";
import useUpdateJob from "components/hooks/useUpdateJob";

type ModalProps = {
  job: JobType;
};

const ModalBody = styled(Box)(({ theme }) => ({}));

const ModalFooter = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const JobNameModal = ({ job }: ModalProps) => {
  const { jobName } = job;

  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState(jobName);

  const { onSubmit } = useUpdateJob(job);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ModalPaper
      title={`Change job name`}
      trigger={<EditIcon sx={{ color: "violet.main" }} />}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody>
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          fullWidth
          variant="outlined"
        />
      </ModalBody>
      <ModalFooter>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          <Button
            variant="contained"
            onClick={() => {
              onSubmit({ jobName: name }, onClose);
              onClose();
            }}
          >
            Update
          </Button>
        </Stack>
      </ModalFooter>
    </ModalPaper>
  );
};

export default JobNameModal;
