// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdp-range_middle{
background-color: #f3e5f5 !important;
color: #9c27b0;
}
.rdp-range_start .rdp-day_button{
background-color: #f3e5f5 !important;
color: #9c27b0;
border-color: #9c27b0;
}

.rdp-range_end .rdp-day_button{
background-color: #f3e5f5 !important;
color: #9c27b0;
border-color: #9c27b0;
}
.rdp-chevron{
    fill:black;
}
.rdp-today{
    color: #e040fb  !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard-activities/style.css"],"names":[],"mappings":"AAAA;AACA,oCAAoC;AACpC,cAAc;AACd;AACA;AACA,oCAAoC;AACpC,cAAc;AACd,qBAAqB;AACrB;;AAEA;AACA,oCAAoC;AACpC,cAAc;AACd,qBAAqB;AACrB;AACA;IACI,UAAU;AACd;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".rdp-range_middle{\nbackground-color: #f3e5f5 !important;\ncolor: #9c27b0;\n}\n.rdp-range_start .rdp-day_button{\nbackground-color: #f3e5f5 !important;\ncolor: #9c27b0;\nborder-color: #9c27b0;\n}\n\n.rdp-range_end .rdp-day_button{\nbackground-color: #f3e5f5 !important;\ncolor: #9c27b0;\nborder-color: #9c27b0;\n}\n.rdp-chevron{\n    fill:black;\n}\n.rdp-today{\n    color: #e040fb  !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
