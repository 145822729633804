import React, { useState, useRef } from "react";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Box,
  Button,
  Stack,
  Paper,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  useMediaQuery,
  CardContent,
} from "@mui/material";
import { JobType } from "types/generic";
import ModalPaper from "components/modals/ModalPaper";
import KnowledgeBaseTextModal from "components/modals/KnowledgeBaseText";
import { sortArrayByDate } from "utils/common";
import { Colors } from "constants/colors";

type GreetingsProps = {
  job: JobType;
  trigger: React.ReactElement;
};

type KnowledgeBaseInstance = {
  id: string;
  type: string;
  text: string;
  createdAt: string;
  active: boolean;
  file?: File;
};

const ModalBody = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const CenterTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
}));

const KnowledgeBaseModal = ({ job, trigger }: GreetingsProps) => {
  const { greeting, jobName } = job;

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const uploadRef = useRef<HTMLInputElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [knowledgeData, setKnowledgeData] = useState([
    {
      id: "0",
      type: "File",
      text: "Speaker 0: Harry Potter and the Philosophers length test",
      createdAt: new Date().toLocaleString(),
      active: true,
    },
    {
      id: "1",
      type: "Text",
      text: "Text2",
      createdAt: new Date().toLocaleString(),
      active: false,
    },
    {
      id: "2",
      type: "Text",
      text: "Test",
      createdAt: new Date().toLocaleString(),
      active: true,
    },
  ]);

  const onClose = () => {
    setOpen(false);
  };

  const createText = (text: string) => {
    setKnowledgeData((prev) => [
      ...prev,
      {
        id: generateNextId(knowledgeData),
        type: "Text",
        text,
        createdAt: new Date().toLocaleString(),
        active: true,
      },
    ]);
  };

  const createFile = (input: any) => {
    if (!input.target?.files) return;

    let file = input.target.files[0];

    let reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function () {
      setKnowledgeData((prev) => [
        ...prev,
        {
          id: generateNextId(knowledgeData),
          type: "File",
          text: reader.result as string,
          createdAt: new Date().toLocaleString(),
          active: true,
          file,
        },
      ]);
    };
  };

  return (
    <ModalPaper
      title={`Fill AI with more facts about the job or business`}
      trigger={trigger}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody sx={{}}>
        <Stack direction={isMobile ? "column" : "row"} gap={2} mb={3}>
          <Typography variant="h3" flex="1" textAlign={"left"}>
            Agent Knowledge Base
          </Typography>
          <Box
            display="flex"
            flexDirection={"row"}
            alignItems={isMobile ? "center" : "end"}
            gap={isMobile ? 1 : 2}
          >
            <KnowledgeBaseTextModal submit={createText} />
            <Button
              style={{
                fontSize: isMobile ? "12px" : "14px",
              }}
              variant="contained"
              fullWidth={isMobile}
            >
              Fetch URL
            </Button>
            <Button
              variant="contained"
              fullWidth={isMobile}
              onClick={() => uploadRef?.current?.click()}
              style={{
                fontSize: isMobile ? "12px" : "14px",
              }}
            >
              Upload File
            </Button>
            <input
              ref={uploadRef}
              type="file"
              accept=".txt"
              style={{ display: "none" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                createFile(e);
                e.currentTarget.value = "";
              }}
            />
          </Box>
        </Stack>
        {isMobile ? (
          sortArrayByDate(knowledgeData).map((instanceData, i) => (
            <KnowledgeInstanceСard instanceData={instanceData} />
          ))
        ) : (
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <CenterTableCell>SOURCE TYPE</CenterTableCell>
                  <CenterTableCell>TEXT</CenterTableCell>
                  <CenterTableCell>DATE CREATED</CenterTableCell>
                  <CenterTableCell>ACTIVE</CenterTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortArrayByDate(knowledgeData).map((instanceData, i) => (
                  <KnowledgeInstanceRow instanceData={instanceData} key={i} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </ModalBody>
    </ModalPaper>
  );
};

type KnowledgeInstanceRowProps = {
  instanceData: KnowledgeBaseInstance;
};

const TextTableCell = styled(CenterTableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "300px",
}));

const KnowledgeInstanceRow: React.FC<KnowledgeInstanceRowProps> = ({
  instanceData,
}) => {
  const { type, text, createdAt, active } = instanceData;
  const [isActive, setActive] = useState(active);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <CenterTableCell>{type}</CenterTableCell>
      <TextTableCell>{text}</TextTableCell>
      <CenterTableCell>{createdAt}</CenterTableCell>
      <CenterTableCell>
        <Stack direction="row" alignItems="center" gap={3}>
          <Switch checked={isActive} onChange={() => setActive(!isActive)} />
          {isActive ? <CheckIcon /> : <InvalidIcon />}
          <Button variant="contained" color="secondary">
            Delete
          </Button>
        </Stack>
      </CenterTableCell>
    </TableRow>
  );
};

const KnowledgeInstanceСard: React.FC<KnowledgeInstanceRowProps> = ({
  instanceData,
}) => {
  const { type, text, createdAt, active } = instanceData;
  const [isActive, setActive] = useState(active);

  return (
    <Paper
      sx={{
        mb: 2,
        borderRadius: 1,
        background: Colors.paperBackground,
        maxWidth: 500,
        width: "100%",
        minWidth: "fit-content",
      }}
    >
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          SOURCE TYPE
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          sx={{ fontWeight: 600, mb: 1 }}
        >
          {type}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          TEXT
        </Typography>
        <Typography variant="body1" color="textPrimary" sx={{ mb: 1.5 }}>
          {text}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          DATE CREATED
        </Typography>
        <Typography variant="body2" color="textPrimary" sx={{ mb: 2 }}>
          {createdAt}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          ACTIVE
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
        >
          <Switch
            checked={isActive}
            onChange={() => setActive(!isActive)}
            size="small"
          />
          {isActive ? <CheckIcon /> : <InvalidIcon />}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <Button variant="contained" fullWidth size="small">
            Delete
          </Button>
        </Stack>
      </CardContent>
    </Paper>
  );
};

const generateNextId = (arrayWithIds: any[]) => {
  return String(+arrayWithIds[arrayWithIds?.length - 1].id + 1);
};

export default KnowledgeBaseModal;
